import React, { useState } from "react";

import { Button } from "@mui/material";

import EventEditDialog from "components/EventEditDialog";
import { IEvent } from "types/events.d";

interface IEditItemsModalProps {
  handleEdit: (event: Partial<IEvent>) => Promise<void>;
  selectedRowIds: string[];
}

const BulkEditItemsModal: React.FC<IEditItemsModalProps> = (props) => {
  const { handleEdit, selectedRowIds = [] } = props;
  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleSubmit = async (event: Partial<IEvent>) => {
    handleEdit(event)
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        setShowEditDialog(false);
      });
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={selectedRowIds?.length === 0}
        onClick={() => setShowEditDialog(true)}
      >
        Edit {selectedRowIds.length} Selected
      </Button>
      {showEditDialog && (
        <EventEditDialog
          open={showEditDialog}
          onClose={() => setShowEditDialog(false)}
          onSave={handleSubmit}
          event={undefined}
          headerText="Bulk Edit Events"
        />
      )}
    </>
  );
};

export default BulkEditItemsModal;
