import { Loader } from '@googlemaps/js-api-loader';

/**
 * This is a wrapper around the Google Maps API client.
 * see https://developers.google.com/maps/documentation/javascript
 */

let googleApiClient: any;

export default async function getGoogleMapsApiClient() {
  if (googleApiClient) {
    return googleApiClient;
  }

  const loader = new Loader({
    apiKey: "AIzaSyCRC9Bqs5Lm9ZIw90HYVP5WTej_zdDMjy4",
    version: 'beta',
    libraries: ['places'],
  });

  googleApiClient = (await loader.load());

  return googleApiClient;
}