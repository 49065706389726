import TokenHandler from "./base";
import { API_HOST } from "../config";

import axios from "axios";

export async function fetchCompletion(prompt: string) {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/openai/completion`, { prompt }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // The body should be text, get that out of the response
  const { text } = response.data;
  return text;
}