import TokenHandler from "./base";
import { API_HOST } from "../config";
import axios from "axios";
import { IService, IPaginationModal } from "types/App";

async function fetchAll(paginationModel: IPaginationModal = {}) {
  const token = TokenHandler.getToken();
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/cities`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const response = await axios.get(url.toString(), {
    params: { query: paginationModel.query, sort: paginationModel.sort },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function fetch(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.get(`${API_HOST}/cities/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function create(data: any) {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/cities`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function update(id: string, data: any) {
  const token = TokenHandler.getToken();
  const response = await axios.put(`${API_HOST}/cities/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function remove(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.delete(`${API_HOST}/cities/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function upload(file: File) {
  const token = TokenHandler.getToken();
  let data = new FormData();
  data.append("file", file);
  const response = await axios.post(`${API_HOST}/cities/upload`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(response);
  return response;
}

const citiesService: IService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  upload,
};

export default citiesService;
