import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  FormLabel,
  Dialog,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { ITagGroup } from "types/tagGroups";
import { ITag } from "types/tags";

const filterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 10,
});

interface Props {
  handleModalConfirm: (selectedTagId: string) => void;
  tags: ITag[] | null;
  addTagModalOpen: boolean;
  setAddTagModalOpen: (open: boolean) => void;
  tagGroup: ITagGroup | null;
}

const AddTagModal: React.FC<Props> = ({
  handleModalConfirm,
  tags,
  addTagModalOpen,
  setAddTagModalOpen,
  tagGroup,
}) => {
  const [selectedTag, setSelectedTag] = useState<string>(undefined);

  const handleModalCancel = () => {
    setSelectedTag(undefined);
    setAddTagModalOpen(false);
  };

  const renderTagsOptionLabel = (option: ITag | undefined) => {
    if (!option) return "";
    const tag = tags.find((tag: any) => tag.id === option);
    return tag?.title || "";
  };

  const getOptions = () => {
    const tagGroupHasTag = (testTag: ITag) => {
      return tagGroup?.tags?.some((tag) => tag.id === testTag.id);
    };
    const optionsToShow = tags?.filter((tag) => !tagGroupHasTag(tag));
    // TODO fix any => tag: any clears out Autocomplet TS error.
    return optionsToShow?.map((tag: any) => tag.id) || [];
  };

  return (
    <Dialog fullWidth open={addTagModalOpen} onClose={() => setAddTagModalOpen(false)}>
      <Grid container direction={"column"} style={{ padding: "20px" }} spacing={4}>
        <Grid item>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="tags">
                Select a tag to add to {tagGroup?.title ? tagGroup?.title : "this tag group"}.
              </FormLabel>
              <Autocomplete
                id="tags"
                value={selectedTag ? selectedTag : ""}
                onChange={(_e, value) => {
                  setSelectedTag(value);
                }}
                filterOptions={filterOptions}
                options={getOptions()}
                getOptionLabel={renderTagsOptionLabel}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item justifyContent={"flex-end"} spacing={4}>
          <Grid item>
            <Button variant="contained" color="error" onClick={handleModalCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!selectedTag}
              variant="contained"
              color="primary"
              onClick={() => {
                handleModalConfirm(selectedTag);
                setSelectedTag(undefined);
              }}
            >
              Add Tag
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddTagModal;
