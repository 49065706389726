import React, { createContext, useCallback, useState, useEffect, ReactNode, useMemo } from "react";
import authService from "../services/auth";
import TokenHandler from "../services/base";

export const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const isAuthenticated = useMemo(() => !!user, [user]);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await authService.me();
      console.log(response);
      setUser(response.user);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const register = useCallback(async (email: string, password: string) => {
    setLoading(true);
    try {
      const _user = await authService.register(email, password);
      setUser(_user);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const login = useCallback(async (username: string, password: string) => {
    try {
      setLoading(true);
      setError(null);
      await authService.login(username, password);
      await fetchUser();
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await authService.logout();
      setUser(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const getToken = useCallback(async () => {
    try {
      const token = TokenHandler.getToken();
      return token;
    } catch (error) {
      console.error(error);
    }
    return null;
  }, []);

  return (
    <AuthContext.Provider value={{ error, loading, isAuthenticated, user, login, logout, register, getToken }}>
      {
        loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <p>Loading...</p>
          </div>
        ) : (
          <>{children}</>
        )
      }
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
