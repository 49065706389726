class TokenHandler {
  constructor() {
    this.getToken = this.getToken.bind(this)
    this.setToken = this.setToken.bind(this)
    this.removeToken = this.removeToken.bind(this)
  }
  getToken() {
    return localStorage.getItem('token')
  }
  setToken(token: string) {
    localStorage.setItem('token', token)
  }
  removeToken() {
    localStorage.removeItem('token')
  }
}
const theTokenHandler = new TokenHandler();
export default theTokenHandler;