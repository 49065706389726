import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import apiKeysService from "services/apiKeys";
import BasicLayout from "components/Layouts/BasicLayout";

const ApiKeys: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "accessToken", headerName: "Access Token", flex: 3 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">ApiKeys</Typography>
        </Grid>
        <Grid item xs={12} sm={8} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/apiKeys/create">
            <Icon>add</Icon>
            Add API Key
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              columns={columns}
              collectionName="apiKeys"
              defaultSortField="name"
              defaultSortDirection="desc"
              service={apiKeysService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default ApiKeys;
