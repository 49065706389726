import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { upload } from "services/images";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import locationsService from "services/locations";
import { ILocationData } from "types/locations.d";
import getGoogleMapsApiClient from "utils/googlemaps";
import LoadingOverlay from "components/LoadingOverlay";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";

const Location: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchLoading, setSearchLoading] = useState<any>(false);
  const [searchText, setSearchText] = useState<any>("");
  const [location, setLocation] = useState<ILocationData | undefined>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { loading: contextLoading, videos, refetchLocations } = useGlobalContext();

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const event = await locationsService.fetch(id);
        setLocation(event);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };
    if (id && id !== "create") {
      fetchLocation();
    }
  }, [id]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;

      for (let file of acceptedFiles) {
        const response = await upload(file, {
          path: "/images/upload",
        });
        setLocation({
          ...location,
          thumbnail: response.imageUrl,
        });
      }
    },
    [location]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSave = async () => {
    try {
      setLoading(true);
      const { events, ...restLocation } = location;
      const newLocation: ILocationData = {
        ...restLocation,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
        status: "published",
        source: "admin",
        formattedAddress: `${location.address1}, ${location.city}, ${location.state} ${location.zip}`,
      };

      if (!newLocation.name || !newLocation.thumbnail || !newLocation.placeId) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        // const q = query(collection(getFirestore(), firestoreCollection), where("placeId", "==", newLocation.placeId));
        // const locationsSnapshot = await getDocs(q);
        // if(locationsSnapshot.docs.length > 0) {
        //   throw new Error('Location already exists');
        // }
        newLocation.createdBy = user;
        newLocation.createdAt = new Date().toISOString();
        await locationsService.create(newLocation);
      } else {
        newLocation.updatedBy = user;
        newLocation.updatedAt = new Date().toISOString();
        await locationsService.update(newLocation.id, newLocation);
      }
      refetchLocations();
      navigate("/locations");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await locationsService.remove(id);
      refetchLocations();
      navigate("/locations");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  const handleSearch = async () => {
    const placesApi = await getGoogleMapsApiClient();
    if (searchText.trim() !== "") {
      try {
        setSearchLoading(true);
        const responseJson = await placesApi.maps.places.Place.searchByText({
          fields: ["displayName", "formattedAddress", "photos", "websiteURI", "types"],
          query: searchText,
        });
        const { places: candidates } = responseJson;
        if (candidates.length > 0) {
          const candidate = candidates[0];
          console.log('candidate', JSON.stringify(candidate, null, 2))
          let thumbnail;
          if (candidate.photos?.length) {
            thumbnail = candidate.photos[0].getURI({ maxWidth: 400 });
          }
          console.log('candidate', JSON.stringify(candidate, null, 2))
          const googleLocation = {
            placeId: candidate.id,
            name: candidate.displayName,
            formattedAddress: candidate.formattedAddress,
            address1: candidate.formattedAddress.split(",")[0]?.trim(),
            city: candidate.formattedAddress.split(",")[1]?.trim(),
            state: candidate.formattedAddress.split(",")[2].split(" ")[1]?.trim() || null,
            zip: candidate.formattedAddress.split(",")[2].split(" ")[2]?.trim() || null,
            thumbnail,
            link: candidate.websiteURI,
            types: candidate.types,
          };
          setLocation({
            ...location,
            ...googleLocation,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSearchLoading(false);
      }
    }
  };
  console.log('location', location)
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Location</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={2}>
                {!location?._id && <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="name">Search Google for Location</FormLabel>
                    <TextField
                      id="searchText"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <br />
                    <Button
                      disabled={searchLoading}
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </FormControl>

                  <Divider />
                  <Divider />
                </Grid>}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <TextField
                      id="name"
                      value={location?.name || ""}
                      onChange={(e) => setLocation({ ...location, name: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="link">Link</FormLabel>
                    <TextField
                      id="link"
                      value={location?.link || ""}
                      onChange={(e) => setLocation({ ...location, link: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="facebook">Facebook</FormLabel>
                    <TextField
                      id="facebook"
                      value={location?.facebook || ""}
                      onChange={(e) => setLocation({ ...location, facebook: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="instagram">Instagram</FormLabel>
                    <TextField
                      id="instagram"
                      value={location?.instagram || ""}
                      onChange={(e) => setLocation({ ...location, instagram: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="address1">Address1</FormLabel>
                    <TextField
                      id="address1"
                      value={location?.address1 || ""}
                      onChange={(e) => setLocation({ ...location, address1: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="city">City</FormLabel>
                    <TextField
                      id="city"
                      value={location?.city || ""}
                      onChange={(e) => setLocation({ ...location, city: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="state">State</FormLabel>
                    <TextField
                      id="state"
                      value={location?.state || ""}
                      onChange={(e) => setLocation({ ...location, state: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="zip">Zip</FormLabel>
                    <TextField
                      id="zip"
                      value={location?.zip || ""}
                      onChange={(e) => setLocation({ ...location, zip: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="video">Video</FormLabel>
                    <Autocomplete
                      value={location?.video?.id || ""}
                      onChange={(e, value) =>
                        setLocation({
                          ...location,
                          video: videos.find((video: any) => video.id === value),
                        })
                      }
                      options={videos.map((video: any) => video.id)}
                      getOptionLabel={(option) =>
                        videos?.find((video) => video.id === option)?.title || option
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={4}>
                {location?.thumbnail && <Grid item xs={12}>
                  <img
                    src={location?.thumbnail}
                    style={{ width: "100%" }}
                    alt="user added location"
                  />
                </Grid>}
                <Grid item xs={12}>
                  <FormLabel htmlFor="description">Images</FormLabel>
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">Events</Typography>
                  {
                    location && location?.events && location.events.length > 0
                      ? <ul>
                        {location.events.map((event: any) => (
                          <li key={event.id}>{event.title}</li>
                        ))}
                      </ul>
                      : <p>No events</p>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={searchLoading || contextLoading || loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Location;
