import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import videosService from "services/videos";
import { AxiosResponse } from "axios";
import { IVideoData } from "types/videos.d";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { useGlobalContext } from "context/GlobalContext";

const Video: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [video, setVideo] = useState<IVideoData>(undefined);
  const [videoError, setVideoError] = useState<any>("");
  const [posterError, setPosterError] = useState<any>("");

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { loading: contextLoading, refetchVideos } = useGlobalContext();

  //fetch specific video if URL has video's id
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        setLoading(true);
        const fetchedVideo = await videosService.fetch(id);
        setVideo(fetchedVideo);
      } catch (e) {
        console.error("Error fetching event:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchVideo();
    }
  }, [id]);

  const onDropVideo = useCallback(
    async (acceptedFiles: any) => {
      if (!acceptedFiles?.length) {
        setVideoError("Video must be an mp4");
        return;
      }
      setVideoError(null);
      setLoading(true);
      const newFiles = [];
      for (let file of acceptedFiles) {
        const response: AxiosResponse = await videosService.upload(file);
        newFiles.push(response);
      }
      if (newFiles.length === 0) return setLoading(false);
      let videoUrl = newFiles[0].data.url;
      if (!videoUrl.startsWith("http")) {
        videoUrl = `https://${videoUrl}`;
      }
      setVideo({
        ...video,
        title: acceptedFiles[0].name.split(".")[0],
        url: videoUrl,
      });
      setLoading(false);
    },
    [video]
  );

  const onDropPoster = useCallback(
    async (acceptedFiles: any) => {
      if (!acceptedFiles?.length) {
        setPosterError("Poster image must be a jpg or png");
        return;
      }
      setLoading(true);
      setPosterError(null);
      const newFiles = [];
      for (let file of acceptedFiles) {
        const response: AxiosResponse = await videosService.upload(file);
        newFiles.push(response);
      }
      if (newFiles.length === 0) return setLoading(false);
      let posterUrl = newFiles[0].data.url;
      if (!posterUrl.startsWith("http")) {
        posterUrl = `https://${posterUrl}`;
      }
      setVideo({
        ...video,
        posterUrl,
      });
      setLoading(false);
    },
    [video]
  );

  const { getRootProps: getRootPropsPoster, getInputProps: getInputPropsPoster } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: onDropPoster,
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropVideo,
    accept: {
      "video/mp4": [],
    },
  });

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!video.title || !video.url) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        // const q = query(collection(getFirestore(), firestoreCollection), where("name", "==", video.name));
        // const videosSnapshot = await getDocs(q);
        // if (videosSnapshot.docs.length > 0) {
        //   throw new Error('Video already exists');
        // }
        video.createdBy = user;
        video.createdAt = new Date().toISOString();
        await videosService.create(video);
      } else {
        video.updatedDate = new Date().toISOString();
        video.updatedBy = user;
        await videosService.update(id, video);
      }
      //Redirect to listings if no errors
      refetchVideos();
      navigate("/videos");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await videosService.remove(id);
      refetchVideos();
      navigate("/videos");
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Video</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="name">Title</FormLabel>
                    <TextField
                      id="name"
                      value={video?.title || ""}
                      onChange={(e) => setVideo({ ...video, title: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  {video?.posterUrl ? (
                    <img
                      src={video?.posterUrl}
                      style={{ width: "169px" }}
                      alt={"video thumbnail"}
                    />
                  ) : (
                    <img src={"https://placehold.co/169x300"} alt={"video thumbnail"} />
                  )}
                </Grid>
                <Grid item xs={10}>
                  <FormLabel htmlFor="description">Poster</FormLabel>
                  {posterError && <Typography color="error">{posterError}</Typography>}
                  <div
                    {...getRootPropsPoster()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputPropsPoster()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  {video?.url ? (
                    <video src={video?.url} style={{ width: "169px" }} controls />
                  ) : (
                    <img src={"https://placehold.co/100x300"} alt={"video thumbnail"} />
                  )}
                </Grid>
                <Grid item xs={10}>
                  <FormLabel htmlFor="description">Video</FormLabel>
                  {videoError && <Typography color="error">{videoError}</Typography>}
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        {id !== "create" && (
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <LoadingOverlay loading={loading || contextLoading} />
      </Grid>
    </BasicLayout>
  );
};

export default Video;
