import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Button, CircularProgress, Dialog, DialogContent, FormControl, FormLabel, IconButton, TextField, Grid, Typography } from "@mui/material";
import { fetchCompletion } from 'services/completions';
import { okayNWAAIActionHost, okayNWAAIToken } from 'config';
import Robot from 'assets/images/icons/robot-line-icon.svg';
import MDBox from 'components/MDBox';

export default function AITextField(props: any) {
  const {
    id,
    label,
    onChange,
    prompt,
    value,
    promptMode = "inline",
    ...restProps
  } = props;
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  
  const disabled = useMemo(() => {
    if(promptMode === "modal") {
      return false;
    }
    return loading || !value || value?.length < 5;
  }, [loading, promptMode, value])

  const handleInlinePrompt = async () => {
    try {
      setLoading(true);
      if(!value || value.length < 5) {
        setError(new Error('Please enter a prompt of at least 10 characters'));
        return;
      }

      setError(null);

      const response = await fetchCompletion(prompt + ': ' + value);
      onChange(response);
    } catch (error: any) {
      console.error('Error generating news:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  const handleModalPrompt = async () => {
    try {
      setLoading(true);
      if(!prompt || prompt.length < 5) {
        setError(new Error('Please enter a prompt of at least 10 characters'));
        return;
      }

      setError(null);

      const response = await fetchCompletion(prompt + ': ' + userPrompt);
      onChange(response);
      setOpen(false);
    } catch (error: any) {
      console.error('Error generating news:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  const handleClick = async () => {
    if(promptMode === "modal") {
      setOpen(true)
    } else {
      handleInlinePrompt();
    }
  };

  return (
    <>
      <FormControl fullWidth>
        <FormLabel htmlFor='title'>
          {label}
          <IconButton aria-label='delete' size='small' onClick={handleClick} disabled={disabled} style={{ width: 30, height: 30 }}>
            <MDBox component="img" src={Robot} style={{ fill: '#FFF' }} alt="robot icon" width="100%" />
          </IconButton>
        </FormLabel>
        <TextField
          id={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...restProps}
        />
      </FormControl>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogContent>
          { error && <Alert severity="error">Failed to add video.</Alert> }
          {
            loading
            ? (
              <Grid container>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center'}}>
                  <CircularProgress />
                </Grid>
              </Grid>
              )
            : <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                Prompt
              </Typography>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  onChange={(e) => setUserPrompt(e.target.value)}
                  value={userPrompt}
                  rows={4}
                  multiline
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleModalPrompt}
                disabled={prompt.length < 5}
              >
                Generate
              </Button>
            </Grid>
          </Grid>
        }
        </DialogContent>
      </Dialog>
    </>
  );
}