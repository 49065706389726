import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { useAuth } from "context/Auth";
import { ITag } from "types/tags";
import tagsService from "services/tags";
import LoadingOverlay from "components/LoadingOverlay";
import { upload } from "services/images";
import { useDropzone } from "react-dropzone";
import BasicLayout from "components/Layouts/BasicLayout";
import { useGlobalContext } from "context/GlobalContext";
import { ChevronLeft } from "@mui/icons-material";

const Tag: React.FC = () => {
  const [tag, setTag] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [error, setError] = useState("");
  const { refetchTags, loading: contextLoading } = useGlobalContext();

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchTag = async () => {
      try {
        setLoading(true);
        const _tag: ITag = await tagsService.fetch(id);
        setTag(_tag);
      } catch (e) {
        console.error("Error fetching tag group:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchTag();
    }
  }, [id]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;
      for (let file of acceptedFiles) {
        const response = await upload(file, {
          path: "/tags/upload",
        });
        setTag({
          ...tag,
          thumbnail: response.imageUrl,
        });
      }
    },
    [tag]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Function to delete a tag
  const handleDelete = async () => {
    try {
      await tagsService.remove(id);
      navigate("/tags");
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
    refetchTags();
  };

  // Function to save a tag
  const handleSave = async () => {
    try {
      setLoading(true);
      const newTag: ITag = {
        ...tag,
      };

      if (!newTag?.title || !newTag?.description || !tag?.thumbnail) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        newTag.createdBy = user;
        newTag.createdDate = new Date().toISOString();
        await tagsService.create(newTag);
      } else {
        newTag.updatedBy = user;
        newTag.updatedAt = new Date().toISOString();
        await tagsService.update(newTag.id, newTag);
      }
      refetchTags();
      //on successful save, navigate back to tags
      navigate("/tags");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h4">Tag</Typography>
            </Grid>
            <Grid item xs={12}>
              {!id ||
                (id === "create" ? (
                  <Typography variant="body1">
                    Create a tag that can be tied to a listing.
                  </Typography>
                ) : (
                  <Typography variant="body1">Edit this tag.</Typography>
                ))}
            </Grid>
            <Grid container item direction={"row"} xs={12} spacing={2}>
              {tag?.thumbnail ? (
                <Grid
                  item
                  container
                  direction={"column"}
                  xs={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid
                    item
                    container
                    style={{
                      border: "1px solid #ccc",
                      height: "200px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid display={"flex"} item>
                      <img
                        src={tag?.thumbnail}
                        style={{ width: "100%", height: "180px" }}
                        alt={"tag"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        cursor: "pointer",
                        fontWeight: 500,
                      }}
                      variant={"subtitle2"}
                      color={"red"}
                      onClick={() => setTag({ ...tag, thumbnail: "" })}
                    >
                      (remove image)
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <FormLabel htmlFor="description">Image *</FormLabel>
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "15px",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                  </div>
                </Grid>
              )}
              <Grid container item direction={"column"} xs={8} spacing={2}>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel required htmlFor="tag-name">
                      Name
                    </FormLabel>
                    <TextField
                      id="tag-name"
                      value={tag?.title || ""}
                      required
                      onChange={(e) => setTag({ ...tag, title: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel required htmlFor="description">
                      Description
                    </FormLabel>
                    <TextField
                      id="description"
                      value={tag?.description || ""}
                      required
                      onChange={(e) => setTag({ ...tag, description: e.target.value })}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
        </Grid>
        <Grid item xs={12} />
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Typography variant="h4" color="alert">
              Danger Zone
            </Typography>
            <Button
              disabled={loading || contextLoading}
              variant="contained"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        )}
        <LoadingOverlay loading={loading || contextLoading} />
        <Snackbar
          open={snackbar.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
      </Grid>
    </BasicLayout>
  );
};

export default Tag;
