import { useMemo, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Logo from "assets/images/logos/OkayNWAGreen.png";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import LoginSignUp from "components/Layouts/LoginSignUp";

// Images
import { Box, FormControl, Input, InputLabel, TextField } from "@mui/material";
import { useAuth } from "../../context/Auth";
import LoadingOverlay from "components/LoadingOverlay";

function Basic(): JSX.Element {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { error, login, loading } = useAuth();

  const submitDisabled = useMemo(() => !email || !password, [email, password]);

  const handleLogin = async () => {
    try {
      await login(email, password);
      navigate("/");
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <LoginSignUp>
      <Card>
        <Box sx={{ textAlign: "center", marginTop: 5 }}>
          {loading ?
            <LoadingOverlay />
            : <img
              key={"title"}
              src={Logo}
              width={300}
              alt={"logo"}
            />}
        </Box>
        <MDBox pt={4} pb={3} px={12}>
          <Grid container justifyContent="center">
            {error && (
              <Grid item xs={12}>
                <MDTypography style={{ textAlign: "center" }} variant="body2" color="error">
                  {error.message || "Something went wrong"}
                </MDTypography>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>

              <InputLabel>Password</InputLabel>
              <FormControl fullWidth>
                <TextField
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MDButton
                disabled={submitDisabled}
                variant="contained"
                color="success"
                fullWidth
                onClick={handleLogin}
              >
                Log In
              </MDButton>
            </Grid>
            {/* <Grid item xs={12}>
              <Link to={"/sign-up"}>Signup</Link>
            </Grid> */}
          </Grid>
        </MDBox>
      </Card>
    </LoginSignUp>
  );
}

export default Basic;
