import { ILocationData } from "types/locations.d";
import { ITagGroup } from "types/tagGroups";

export const isStringAValidDate = (date?: string | number) => {
  //verify date is a string (not a number or undefined)
  if (!date || !isNaN(Number(date))) {
    return false;
  }
  //check if string is valid date
  const dateTest: Date | string = new Date(date) || "Invalid Date";
  return dateTest !== "Invalid Date";
};

export const getLocationById = ({
  locationId,
  locations,
}: {
  locationId: string;
  locations: ILocationData[];
}) => {
  if (!locationId || !locations) return null;
  return locations.find((location) => location.id === locationId);
};

export const getTagGroupById = ({
  selectedTagGroupId,
  tagGroups,
}: {
  selectedTagGroupId: string;
  tagGroups: ITagGroup[];
}) => {
  return tagGroups.find((tagGroup) => tagGroup.id === selectedTagGroupId);
};

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};
