import axios from "axios";
import { API_HOST } from "../config";
import TokenHandler from "./base";

export const upload = async (params: any, options: any = {}) => {
  const path = options.path || "/images/upload";
  const token = TokenHandler.getToken();
  try {
    let data = new FormData();
    data.append("file", params);
    const response = await fetch(API_HOST + path, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (err) {
    console.log("Error", err);
  }
};

export const generate = async ({
  prompt,
  path = "/images/upload",
}: {
  prompt: string;
  path?: string;
}) => {
  const token = TokenHandler.getToken();
  try {
    const response = await axios.post(
      `${API_HOST}/images/generate`,
      { prompt, path },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log("Error", err);
  }
};

const imageService = {
  upload,
  generate,
};

export default imageService;
