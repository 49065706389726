import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  Alert,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import utilsService from "services/utils";
import Thumbnail from "components/Thumbnail";
import LocationSelect from "components/LocationSelect";
import eventsService from "services/events";
// alert icon
import { Edit, Warning } from "@mui/icons-material";
import EventEditDialog from "components/EventEditDialog";
import TagMultiSelect from "components/TagMultiSelect";
import VideoSelect from "components/VideoSelect";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";

// const exampleResponse = [
//   {
//     "title": "Nuclear Victims' Remembrance Day Commemoration",
//     "start": "2024-03-01T18:00:00",
//     "end": "2024-03-01T20:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/Image-2-27-24-at-8.49-AM-1.jpg",
//     "description": "<p>The Marshallese Educational Initiative, a Springdale based nonprofit, hosts Nuclear Legacy Week 2024 (NLW24), to bring Marshallese community members together with other frontline, nuclear affected community members from around the globe to share stories and experiences and educate about the consequences of nuclear weapons’ use, testing, uranium mining and milling, and waste storage.&nbsp;</p>\n<p>Join us on March 1 at the Jones Center for Nuclear Victims' Remembrance Day commemoration. Details here .</p>\n<p>Hosted by the Marshallese Educational Initiative.</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/nuclear-victims-remembrance-day-commemoration-5957"
//   },
//   {
//     "title": "Springdale Bike Club Taco Tuesday Ride",
//     "start": "2024-03-05T19:00:00",
//     "end": "2024-03-05T21:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/Taco-Tuesday-7.png",
//     "description": "<p>Taco Tuesday rides are every Tuesday. Riders depart from Shiloh Square at 7PM, ride 12 miles at an avg pace of 11-14 mph, traveling over mostly Greenway and protected bike lane. Following the ride, the Club meets up at Mr Taco Loco for tacos &amp; drinks.&nbsp;</p>\n<p>Check their<a href=\"https://www.facebook.com/groups/springdalebikeclub/\" target=\"_self\" style=\"\" rel=\"noopener\"> FB group</a> for updates and weather cancellations.</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/springdale-bike-club-taco-tuesday-ride-5966"
//   },
//   {
//     "title": "Women For Women Brunch",
//     "start": "2024-03-08T08:30:00",
//     "end": "2024-03-08T11:30:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/https-cdn.evbuc_.com-images-696233109-481895968015-1-original.20240213-163200.jpeg",
//     "description": "<p><strong>Please Join Us for The Women for Women brunch celebrating International Women's Day benefitting the NWA Women's Shelter.</strong></p>\n<p>By <strong>The Scout Guide Northwest Arkansas.</strong></p>",
//     "price": "$75 – $750",
//     "link": "https://downtown-springdale.loxi.io/women-for-women-brunch-6016"
//   },
//   {
//     "title": "American Legion Pancake Breakfast",
//     "start": "2024-03-09T07:00:00",
//     "end": "2024-03-09T10:30:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2023/12/Resized_20231201_152848.jpeg",
//     "description": "<p>Head to the Springdale American Legion Post 139 for a pancake breakfast from 7AM-10:30AM. Enjoy pancakes, waffles, scrambled eggs, bacon, sausage, coffee &amp; juice. Located at 200 S Spring Street.</p>\n<p>$5 donation/person</p>\n<p>$15 donation/family</p>\n<p>Proceeds benefit programs offered by Post 139.&nbsp;</p>",
//     "price": "$5 – $15",
//     "link": "https://downtown-springdale.loxi.io/american-legion-pancake-breakfast-5767"
//   },
//   {
//     "title": "Springdale Bike Club Taco Tuesday Ride",
//     "start": "2024-03-12T19:00:00",
//     "end": "2024-03-12T21:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/Taco-Tuesday-7.png",
//     "description": "<p>Taco Tuesday rides are every Tuesday. Riders depart from Shiloh Square at 7PM, ride 12 miles at an avg pace of 11-14 mph, traveling over mostly Greenway and protected bike lane. Following the ride, the Club meets up at Mr Taco Loco for tacos &amp; drinks.&nbsp;</p>\n<p>Check their<a href=\"https://www.facebook.com/groups/springdalebikeclub/\" target=\"_self\" style=\"\" rel=\"noopener\"> FB group</a> for updates and weather cancellations.</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/springdale-bike-club-taco-tuesday-ride-5969"
//   },
//   {
//     "title": "Medium Mix Tape Music Series",
//     "start": "2024-03-13T19:00:00",
//     "end": "2024-03-13T21:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/01/Image-1-10-24-at-12.47-PM-2.jpg",
//     "description": "<p>The Mixtape Music Series is a free, all-ages, live music series at the Medium. The talented musicians performing are recipients of the Creative Exchange Fund. You can expect to hear new, innovative music with musicians who are excited to take creative risks. Each night includes two 45 to 60-minute acts.&nbsp;</p>\n<p><br></p>\n<p>7-9PM</p>",
//     "price": "Free",
//     "link": "https://downtown-springdale.loxi.io/medium-mix-tape-music-series-5864"
//   },
//   {
//     "title": "Greening of the Creek St. Patrick’s Day Festival",
//     "start": "2024-03-16T15:30:00",
//     "end": "2024-03-16T17:30:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/03/Screenshot2023-09-22at4.59.25PM_og.png",
//     "description": "<p>On Saturday, March 16th, 2024, the Rotary Club of Springdale will host the first Greening of the Creek St. Patrick’s Day event in Downtown Springdale!</p>\n<p>At 3:30 p.m., Leprechauns will race down Spring Creek in Turnbow Park to win a $1000 Pot of Gold for the Lucky Sponsor of the Winning Leprechaun. Leprechauns may be adopted for $10 at www.springdalerotary.com. All proceeds over expenses go to building Gerald Harp Treehouse Park.</p>\n<p>There will also be a free family-friendly Scavenger Hunt with small prizes beginning at 10:00 a.m. Game forms will be available for download at www.springdalerotary.com beginning Thursday, March 14, and must be returned to Turnbow Park no later than 3:00 p.m. on Saturday, March 16.</p>\n<p>There will also be a kids’ book giveaway and other shenanigans! Please plan to come out and enjoy the festivities! The sponsor of the winning leprechaun and winners of the Scavenger Hunt do not need to be present to win, but you don't want to miss out on the fun!</p>",
//     "price": "Free",
//     "link": "https://downtown-springdale.loxi.io/greening-of-the-creek-st-patricks-day-festival-6065"
//   },
//   {
//     "title": "Saint Patrick's Parade",
//     "start": "2024-03-16T16:30:00",
//     "end": "2024-03-16T20:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/424923243_18394533076068892_1286855427444333620_n.jpg",
//     "description": "<p>Lucky you, two of your favorite downtown Springdale spots are ‘Dublin’ the fun this St Patrick’s Day!</p>\n<p>Come join us for our ‘Lucky Number 7’ &nbsp;Anniversary Party all day Saturday 3/16, then be a part of the very first Smallest Saint Patrick’s Parade over to @bauhausbiergarten at 5 (dining district cups in hand!)&nbsp;</p>\n<p>Stay and enjoy live music from 6 to 8, or parade back again!&nbsp;</p>\n<p>And as if it won’t be fun enough, we’ll both be pouring $5 pints of Guinness to help keep you hydrated on your travels!&nbsp;</p>\n<p>So come help us celebrate another Odd year and the luck o’ the Irish it took to get us here!</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/saint-patricks-parade-5951"
//   },
//   {
//     "title": "Springdale Bike Club Taco Tuesday Ride",
//     "start": "2024-03-19T19:00:00",
//     "end": "2024-03-19T21:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/Taco-Tuesday-7.png",
//     "description": "<p>Taco Tuesday rides are every Tuesday. Riders depart from Shiloh Square at 7PM, ride 12 miles at an avg pace of 11-14 mph, traveling over mostly Greenway and protected bike lane. Following the ride, the Club meets up at Mr Taco Loco for tacos & drinks.&nbsp;</p>\n<p>Check their<a href=\"https://www.facebook.com/groups/springdalebikeclub/\" target=\"_self\" style=\"\" rel=\"noopener\"> FB group</a> for updates and weather cancellations.</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/springdale-bike-club-taco-tuesday-ride-5970"
//   },
//   {
//     "title": "Spring Art Gala & Exclusive Wine Dinner",
//     "start": "2024-03-21T16:30:00",
//     "end": "2024-03-21T18:30:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/03/327021983_2529233327215775_8889264355151713985_n-3.jpg",
//     "description": "<p>Join us for a celebration of art, cuisine, and community at Bienvenue Restaurant's Spring Art Gala, featuring stunning artworks from local artists. Enjoy an evening filled with sparkling wine, delicious passed hors d'oeuvres, and the vibrant spirit of Downtown Springdale.<br><br>General Admission: 4:30 PM - 6:30 PM | Tickets: $20&a nbsp;</p><p>All General Admission proceeds will benefit the Spring Creek Food Hub, dedicated to connecting farmers with the community and ensuring access to healthy food for all Northwest Arkansans.</p>",
//     "price": "$20",
//     "link": "https://downtown-springdale.loxi.io/spring-art-gala-exclusive-wine-dinner-at-bienvenue-restaurant-6060"
//   },
//   {
//     "title": "Bauhaus Salon Series",
//     "start": "2024-03-21T18:00:00",
//     "end": "2024-03-21T20:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/03/bauhaus-march-sacred-hollow-farm-regenerative-7611.jpg",
//     "description": "<p>Save the date for the first Bauhaus Salon Series on March 21 from 6-8 pm at Bauhaus Biergarten. Enjoy great discussion and food.</p>\n<p>Regenerative farming &amp; gardening is a valuable way to use natural resources to build soil health &amp; create biodiversity. From small home gardens to large farms, we can all apply these beneficial practices. Join Caleb &amp; Sydney from Sacred Hollow Farm, about how they utilize regenerative practices on their farm. They will discuss the importance of healthy soil structure, how to create biodiversity in the garden, water conservation &amp; more so we may learn apply these practices in our own gardens.&nbsp;</p>\n<p>Thursday March 21st 6-8.&nbsp;</p>\n<p>Brought to you by Bauhaus Biergarten, Meredith Mashubrn Photography “Wild Rising” series, Daniel Hintz and Chef Jennifer Hill Booker.</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/bauhaus-salon-series-6057"
//   },
//   {
//     "title": "Springdale Bike Club Taco Tuesday Ride",
//     "start": "2024-03-26T19:00:00",
//     "end": "2024-03-26T21:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/Taco-Tuesday-7.png",
//     "description": "Taco Tuesday rides are every Tuesday. Riders depart from Shiloh Square at 7PM, ride 12 miles at an avg pace of 11-14 mph, traveling over mostly Greenway and protected bike lane. Following the ride, the Club meets up at Mr Taco Loco for tacos & drinks. Check their FB group for updates and weather cancellations.",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/springdale-bike-club-taco-tuesday-ride-5971"
//   },
//   {
//     "title": "Table Talks at The Medium",
//     "start": "2024-03-27T18:00:00",
//     "end": "2024-03-27T20:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/01/1-2-2.png",
//     "description": "Table Talks is a monthly gathering bringing together local creatives and the public for an evening of inspiration and community building over a shared meal. Table Talks feature local creatives who share their creative process and journey based on a given topic. Table Talks are free and open to all, but registration is encouraged to save a spot (and plate!) at the table.",
//     "price": "Free",
//     "link": "https://downtown-springdale.loxi.io/table-talks-at-the-medium-5897"
//   }
// ];
const EventsFromJSON: React.FC = () => {
  const [url, setUrl] = React.useState<string>("");
  const [key, setKey] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);
  const [field, setField] = React.useState<string>("");
  const [value, setValue] = React.useState<any>(null);
  const [events, setEvents] = React.useState<any[]>([]);
  const [editing, setEditing] = React.useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "thumbnail",
      headerName: "",
      width: 100,
      renderCell: (params: any) => (
        <img src={params?.value} alt="Event" style={{ width: "50px" }} />
      ),
    },
    { field: "status", headerName: "Status", width: 100 },
    { field: "title", headerName: "Title", width: 400 },
    {
      field: "start",
      headerName: "Start",
      width: 200,
      renderCell: (params: any) => {
        const d = new Date(params?.value);
        return <span>{d.toLocaleString()}</span>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 400,
      renderCell: (params: any) => <span>{params?.value?.name}</span>,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "link",
      headerName: "Link",
      width: 400,
      renderCell: (params: any) => (
        <Link to={params?.value} target="_blank">
          {params?.value}
        </Link>
      ),
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 400,
      renderCell: (params: any) => (
        <FormControl fullWidth>
          <TagMultiSelect
            selectedTags={params?.value}
            setSelectedTags={(selectedTags: any) => {
              setEvents((prevEvents) =>
                prevEvents.map((e) => {
                  if (e.id === params.row.id) {
                    return { ...e, tags: selectedTags };
                  }
                  return e;
                })
              );
            }}
          />
        </FormControl>
      ),
    },
    {
      field: "video",
      headerName: "Video",
      width: 300,
      renderCell: (params: any) => (
        <FormControl fullWidth>
          <VideoSelect
            value={params?.value}
            onChange={(video) => {
              setEvents((prevEvents) =>
                prevEvents.map((e) => {
                  if (e.id === params.row.id) {
                    return { ...e, video };
                  }
                  return e;
                })
              );
            }}
          />
        </FormControl>
      ),
    },
    {
      field: "id",
      headerName: "Remove?",
      width: 100,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={() => {
              setEditing(params.row);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              setEvents((prevEvents) => prevEvents.filter((e) => e.id !== params?.value));
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </>
      ),
    },
  ];

  const handleSearch = async () => {
    try {
      setLoading(true);
      if (!url) {
        return;
      }
      const data = await utilsService.eventObjectsFromJSON(url, key);
      setEvents(data.map((e: any) => ({ ...e, id: uuidv4(), status: "pending" })));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setEvents([]);
    setUrl("");
    setKey("");
  };

  const handleSave = async () => {
    try {
      if (events.length === 0) {
        return;
      }
      setLoading(true);
      for (let e of events) {
        try {
          const { id, location, ...rest } = e;
          await eventsService.create({
            ...rest,
            location: location?.id,
          });
          // Add a success status to the event
          setEvents((prevEvents) =>
            prevEvents.map((pe) => {
              if (pe.id === e.id) {
                return { ...e, status: "success" };
              }
              return pe;
            })
          );
          continue;
        } catch (error) {
          setError(error);
        }
        // Add a failure status to the event
        setEvents((prevEvents) =>
          prevEvents.map((pe) => {
            if (pe.id === e.id) {
              return { ...e, status: "failure" };
            }
            return pe;
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEditing = (newEvent: any) => {
    if (newEvent) {
      setEvents((prevEvents) =>
        prevEvents.map((pe) => {
          if (pe.id === newEvent.id) {
            return newEvent;
          }
          return pe;
        })
      );
      setEditing(null);
    }
  };

  const handleApplyAll = () => {
    if (field && value) {
      setEvents((prevEvents) => prevEvents.map((e) => ({ ...e, [field]: value })));
      setField("");
      setValue(null);
    }
  };
  console.log("editing", editing);
  return (
    <BasicLayout>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert icon={<Warning />} color="error">
              {error?.message || "Something went wrong. Check the console."}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Add Events from JSON</Typography>
        </Grid>
        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4} spacing={2} display={"flex"} flexDirection={"column"} gap={"20px"}>
          <FormControl fullWidth required>
            <TextField
              id="url"
              label="URL"
              variant="outlined"
              required
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="key"
              label="Key"
              variant="outlined"
              onChange={(e) => setKey(e.target.value)}
            />
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            <Icon>add</Icon>
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}></Grid>
        {events.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Apply To All</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel>Field</FormLabel>
                    <Select
                      variant="outlined"
                      value={field}
                      onChange={(e) => {
                        setValue(null);
                        setField(e.target.value);
                      }}
                    >
                      <MenuItem value="">Select Field</MenuItem>
                      <MenuItem value="thumbnail">Thumbnail</MenuItem>
                      <MenuItem value="title">Title</MenuItem>
                      <MenuItem value="description">Description</MenuItem>
                      <MenuItem value="start">Start</MenuItem>
                      <MenuItem value="location">Location</MenuItem>
                      <MenuItem value="price">Price</MenuItem>
                      <MenuItem value="link">Link</MenuItem>
                      <MenuItem value="video">Video</MenuItem>
                      <MenuItem value="tags">Tags</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {field === "thumbnail" && (
                    <Thumbnail
                      imageUrl={events?.[0].thumbnail}
                      onChange={(url) =>
                        setEvents((prevEvents) => prevEvents.map((e) => ({ ...e, thumbnail: url })))
                      }
                    />
                  )}
                  {field === "title" && (
                    <TextField
                      id="title"
                      label="Title"
                      variant="outlined"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )}
                  {field === "description" && (
                    <TextField
                      id="description"
                      label="Description"
                      variant="outlined"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )}
                  {field === "start" && (
                    <TextField
                      id="start"
                      label="Start"
                      variant="outlined"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )}
                  {field === "location" && (
                    <LocationSelect onChange={(location) => setValue(location)} />
                  )}
                  {field === "price" && (
                    <TextField
                      id="price"
                      label="Price"
                      variant="outlined"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )}
                  {field === "link" && (
                    <TextField
                      id="link"
                      label="Link"
                      variant="outlined"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )}
                  {field === "video" && (
                    <FormControl fullWidth>
                      <FormLabel>Video</FormLabel>
                      <VideoSelect onChange={(location) => setValue(location)} />
                    </FormControl>
                  )}
                  {field === "tags" && (
                    <FormControl fullWidth>
                      <FormLabel>Tags</FormLabel>
                      <TagMultiSelect
                        selectedTags={value}
                        setSelectedTags={(selectedTags: any) => setValue(selectedTags)}
                      />
                    </FormControl>
                  )}
                  {value && (
                    <Button variant="contained" color="primary" onClick={handleApplyAll}>
                      Apply
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Events</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={events}
                  getRowId={(row) => row.id}
                  columns={columns}
                  sx={{ overflowX: "scroll" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                <Icon>add</Icon>
                Save
              </Button>
            </Grid>
          </>
        )}
        <EventEditDialog
          event={editing}
          open={Boolean(editing)}
          onClose={() => setEditing(null)}
          onSave={handleSaveEditing}
        />
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default EventsFromJSON;
