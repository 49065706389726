import { Box } from "@mui/material";
import LoadingOverlay from "components/LoadingOverlay";
import Sidenav from 'components/Sidenav';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  loading?: boolean;
}

const BasicLayout: React.FC<Props> = ({ children, loading = false }: Props) => {
  return (
    <>
    <Box>
      <Sidenav>
        {children}
      </Sidenav>
      <LoadingOverlay loading={loading} />
    </Box>
    </>
  )
}

export default BasicLayout;
