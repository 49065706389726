import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setOpenConfigurator } from "context";
import { Grid, useMediaQuery } from "@mui/material";
import theme from "assets/theme";
import { useAuth } from "context/Auth";

import styled from "@emotion/styled";

export const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  isOpen?: boolean;
  handleDrawerToggle?: () => void;
}

function DashboardNavbar({
  absolute,
  light,
  isOpen = true,
  handleDrawerToggle,
}: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState<any>(false);
  const [openUserMenu, setUserOpenMenu] = useState<any>(false);
  const route = useLocation().pathname.split("/").slice(1);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
  }, [dispatch, fixedNavbar]);

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleOpenUserMenu = (event: any) => setUserOpenMenu(event.currentTarget);
  const handleCloseUserMenu = () => setUserOpenMenu(false);

  const { logout, user } = useAuth();

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>user</Icon>} title="Profile" />
      <NotificationItem onClick={handleLogout} icon={<Icon>user</Icon>} title="Logout" />
    </Menu>
  );
  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };
  const renderUserMenu = () => (
    <Menu
      anchorEl={openUserMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUserMenu)}
      onClose={handleCloseUserMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={() => navigate("/profile")}
        icon={<Icon>user</Icon>}
        title="Profile"
      />
      <NotificationItem onClick={handleLogout} icon={<Icon>user</Icon>} title="Logout" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={navbarContainer}>
        <Grid container display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
          <Grid item paddingLeft={"25px"}>
            {!isSmallScreen && (
              <Breadcrumbs
                icon="home"
                title={route[route.length - 1]}
                route={route}
                light={light}
              />
            )}
          </Grid>

          <Grid item>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarMobileMenu}
              onClick={() => {
                handleDrawerToggle();
              }}
            >
              <Icon sx={iconsStyle} fontSize="medium">
                {isOpen ? "menu" : "menu_open"}
              </Icon>
            </IconButton>

            <IconButton
              sx={navbarIconButton}
              size="small"
              disableRipple
              onClick={handleOpenUserMenu}
            >
              {user.imageUrl ? (
                <ProfileImage alt="avatar" src={user.imageUrl} />
              ) : (
                <Icon sx={iconsStyle}>account_circle</Icon>
              )}
            </IconButton>
            {renderUserMenu()}

            {/* <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              onClick={handleConfiguratorOpen}
            >
              <Icon sx={iconsStyle}>settings</Icon>
            </IconButton> */}
            {/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
            {renderMenu()}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
