import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { upload } from "services/images";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";
import { IOrganization } from "types/organizations.d";
import organizationsService from "services/organizations";

const Organization: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [organization, setOrganization] = useState<IOrganization | undefined>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { loading: contextLoading, refetchOrganizations } = useGlobalContext();

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const organization = await organizationsService.fetch(id);
        setOrganization(organization);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };
    if (id && id !== "create") {
      fetchOrganization();
    }
  }, [id]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;

      for (let file of acceptedFiles) {
        const response = await upload(file, {
          path: "/images/upload",
        });
        setOrganization({
          ...organization,
          logo: response.imageUrl,
        });
      }
    },
    [organization]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSave = async () => {
    try {
      setLoading(true);
      const newOrganization: IOrganization = {
        ...organization,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
      };

      if (!newOrganization.name || !newOrganization.website) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        // const q = query(collection(getFirestore(), firestoreCollection), where("placeId", "==", newLocation.placeId));
        // const locationsSnapshot = await getDocs(q);
        // if(locationsSnapshot.docs.length > 0) {
        //   throw new Error('Location already exists');
        // }
        newOrganization.createdBy = user;
        newOrganization.createdAt = new Date().toISOString();
        await organizationsService.create(newOrganization);
      } else {
        newOrganization.updatedBy = user;
        newOrganization.updatedAt = new Date().toISOString();
        await organizationsService.update(newOrganization.id, newOrganization);
      }
      refetchOrganizations();
      navigate("/organizations");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await organizationsService.remove(id);
      refetchOrganizations();
      navigate("/organizations");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Organization</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={2}>
                <Divider />
                <Divider />
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <TextField
                      id="name"
                      value={organization?.name || ""}
                      onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <TextField
                      id="description"
                      multiline
                      rows={4}
                      value={organization?.description || ""}
                      onChange={(e) =>
                        setOrganization({ ...organization, description: e.target.value })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="link">Website</FormLabel>
                    <TextField
                      id="link"
                      value={organization?.website || ""}
                      onChange={(e) =>
                        setOrganization({ ...organization, website: e.target.value })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="link">Google Calendar Link</FormLabel>
                    <TextField
                      id="link"
                      value={organization?.googleCalendarLink || ""}
                      onChange={(e) =>
                        setOrganization({ ...organization, googleCalendarLink: e.target.value })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="instagram">Instagram</FormLabel>
                    <TextField
                      id="instagram"
                      value={organization?.instagram || ""}
                      onChange={(e) =>
                        setOrganization({ ...organization, instagram: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="facebook">Facebook</FormLabel>
                    <TextField
                      id="instagram"
                      value={organization?.facebook || ""}
                      onChange={(e) =>
                        setOrganization({ ...organization, facebook: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="twitter">Twitter</FormLabel>
                    <TextField
                      id="instagram"
                      value={organization?.twitter || ""}
                      onChange={(e) =>
                        setOrganization({ ...organization, twitter: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={4}>
                {organization?.logo && (
                  <Grid item xs={12}>
                    <img src={organization?.logo} style={{ width: "100%" }} alt="user added logo" />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormLabel htmlFor="description">Logo</FormLabel>
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={contextLoading || loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Organization;
