import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useAuth } from "../../context/Auth";
import { FormControl, InputLabel, TextField } from "@mui/material";


// Authentication layout components
import LoginSignUp from "components/Layouts/LoginSignUp";

function Cover(): JSX.Element {
  const navigate = useNavigate();
  
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);

  const { register, loading } = useAuth();

  const submitDisabled = useMemo(() => !email || !password, [email, password]);

  const handleSignup = async () => {
    try {
      const response = await register(email, password);
      navigate("/dashboard");
    } catch (error: any) {
      setError(error);
    }
  };

  return (
    <LoginSignUp>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={12}>
          <Grid container justifyContent="center">
            {error && (
              <Grid item xs={12}>
                <MDTypography style={{ textAlign: "center" }} variant="body2" color="error">
                  {error.message}
                </MDTypography>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputLabel>Name</InputLabel>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>

              <InputLabel>Password</InputLabel>
              <FormControl fullWidth>
                <TextField
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <MDButton
                disabled={submitDisabled}
                variant="contained"
                color="success"
                fullWidth
                onClick={handleSignup}
              >
                Sign Up
              </MDButton>
            </Grid> */}
          </Grid>
        </MDBox>
      </Card>
    </LoginSignUp>
  );
}

export default Cover;
