import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  createFilterOptions,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
  Icon,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AITextField from "components/AITextField";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import dayjs from "dayjs";
import { upload } from "services/images";
import eventsService from "services/events";
import { isStringAValidDate } from "utils/commonFunctions";
import { useGlobalContext } from "context/GlobalContext";
import LoadingOverlay from "components/LoadingOverlay";
import { ITagGroup } from "types/tagGroups";
import BasicLayout from "components/Layouts/BasicLayout";
import { DayOfWeek, IEvent, IGenerateEventImageResponse, StatusEnum } from "types/events.d";
import { ILocationData } from "types/locations.d";

import OrganizationSelect from "components/OrganizationSelect";
import PromotionSelect from "components/PromotionSelect";

interface IEventAPIRequest extends Omit<IEvent, "location"> {
  location: string;
}
const Event: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isRecurringEvent, setIsRecurringEvent] = useState(false);
  const [recurringDayOfWeek, setRecurringDayOfWeek] = useState<DayOfWeek>(null); // 0 = Sunday, 1 = Monday, etc.
  const [numberOfWeeksToRepeat, setNumberOfWeeksToRepeat] = useState(1);
  const [error, setError] = useState("");

  const DEFAULT_CREATE_EVENT: IEvent = {
    start: "",
    title: "",
    __v: 0,
    createdDate: new Date().toISOString(),
    link: "",
    location: undefined,
    slug: "",
    tagIds: [],
    thumbnail: "",
    updatedDate: "",
    tags: [],
    id: "",
    updatedBy: user?.uid,
    updatedAt: new Date().toISOString(),
    status: StatusEnum.Published,
    source: "admin",
  };
  const [event, setEvent] = useState<IEvent>(id ? { ...DEFAULT_CREATE_EVENT } : null);

  const { loading: contextLoading, locations, tags, videos, tagGroups } = useGlobalContext();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const event: IEvent = await eventsService.fetch(id);
        //not all events have status, so we set it to published by default
        if (!event.status) {
          event.status = StatusEnum.Published;
        }
        setEvent(event);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };

    if (id && id !== "create") {
      fetchEvent();
    }
  }, [id]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;
      for (let file of acceptedFiles) {
        const response = await upload(file, {
          path: "/events/upload",
        });
        setEvent({
          ...event,
          thumbnail: response.imageUrl,
        });
      }
    },
    [event]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const navigate = useNavigate();

  const handleBulkCreate = useCallback(
    async (newEvent: IEventAPIRequest) => {
      const eventDateArray = [...Array(numberOfWeeksToRepeat)].map((_, i) => {
        if (i === 0) {
          return newEvent.start;
        } else {
          const firstDate = dayjs(newEvent.start);
          const currentDate = firstDate.add(i, "week");
          return currentDate.toISOString();
        }
      });

      const createActions = eventDateArray.map(async (newDate) => {
        try {
          const updatedEvent = { ...newEvent, start: newDate };
          await eventsService.create(updatedEvent);
        } catch (error: any) {
          //TODO Snackbar this error
          console.error("Error creating:", error);
        }
      });
      await Promise.allSettled(createActions).then(() => {
        return navigate(-1);
      });
    },
    [navigate, numberOfWeeksToRepeat]
  );

  const handleSave = async () => {
    try {
      setLoading(true);

      const newEvent: IEventAPIRequest = {
        ...event,
        location: event?.location?.id || "",
        locationName: event.location?.name || "",
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
        source: "admin",
        tagIds: event.tags?.map((tag: any) => tag.id),
      };
      if (
        !newEvent.start ||
        !newEvent.title ||
        // !newEvent.description ||
        // !newEvent.price ||
        !newEvent.link
      ) {
        throw new Error("Please fill in all fields");
      }
      // Make sure that start is an instance of Timestamp
      if (!isStringAValidDate(newEvent.start)) {
        throw new Error("Start must be a valid date");
      }
      if (id === "create") {
        newEvent.createdBy = user;
        newEvent.createdDate = new Date().toISOString();
        delete newEvent.id;
        delete newEvent._id;

        if (isRecurringEvent) {
          if (!event.start || recurringDayOfWeek === null || numberOfWeeksToRepeat === null) {
            throw new Error("Please fill event date information");
          } else {
            await handleBulkCreate(newEvent);
          }
        } else {
          await eventsService.create(newEvent);
        }
      } else {
        newEvent.updatedBy = user;
        newEvent.updatedAt = new Date().toISOString();
        await eventsService.update(newEvent.id, newEvent);
      }

      // Redirect to previous page after saving
      navigate(-1);
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await eventsService.remove(id);
      navigate("/events");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const renderTagsOptionLabel = (option: any) => {
    const tag = tags.find((tag: any) => tag.id === option);
    if (tag) {
      if (tag && tagGroups.length) {
        const firstTagGroup = tagGroups.find((tagGroup: ITagGroup) =>
          tagGroup.tags.some((t: any) => t.id === tag.id)
        );
        if (firstTagGroup) {
          return `${tag.title} - ${firstTagGroup.title}`;
        }
        return tag?.title;
      }
    }
    return option || "";
  };

  const handleGenerateClick = async () => {
    try {
      setLoading(true);
      if (!event.description) {
        alert("Please supply a description");
        throw new Error("Please fill in all fields");
      }
      // Use openai to generate the news article
      const response: IGenerateEventImageResponse = await eventsService.generate({
        prompt: event.description,
      });
      setEvent({
        ...event,
        thumbnail: response.image,
      });
    } catch (error: any) {
      console.error("Error generating news:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <BasicLayout>
      <Grid container spacing={10}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Event</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="location">Location</FormLabel>
                    <Autocomplete
                      value={event?.location || null}
                      onChange={(_e: any, value: ILocationData) => {
                        setEvent({
                          ...event,
                          location: value,
                          video: value?.video || null,
                        });
                      }}
                      getOptionKey={(option) => option.id || ""}
                      options={locations || []}
                      getOptionLabel={(option) =>
                        locations?.find((location) => location.id === option.id)?.name || ""
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {event?.locationName && <FormHelperText>{event.locationName}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <AITextField
                    id="title"
                    label="Title"
                    prompt="From the following details, create a title of an event. The title should be descriptive and engaging while being lesss than 6 words."
                    value={event?.title || ""}
                    onChange={(e: any) => setEvent({ ...event, title: e })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AITextField
                    id="description"
                    label="Description"
                    prompt="From the following details, create a description of an event. The title should be descriptive and engaging and be at least 3 sentences long."
                    promptMode="modal"
                    value={event?.description || ""}
                    multiline
                    rows={4}
                    onChange={(e: any) => setEvent({ ...event, description: e })}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-between"}
                  gap={5}
                >
                  <FormControl sx={{ display: "flex", flex: 1 }}>
                    <FormLabel htmlFor="datetime">
                      {isRecurringEvent ? "First Datetime" : "Datetime"}
                    </FormLabel>
                    <DateTimePicker
                      format={event?.start ? "dddd, MMMM D, YYYY @ h:mm A" : "MM/DD/YYYY hh:mm aa"}
                      value={event?.start ? dayjs(event?.start) : null}
                      onChange={(value: any) => {
                        setEvent({ ...event, start: value.toDate().toISOString() });
                        setRecurringDayOfWeek(value.toDate().getDay() as DayOfWeek);
                      }}
                    />
                  </FormControl>
                  {!event?._id && (
                    <FormControl>
                      <FormLabel focused={false} htmlFor="status-group-label-recurring">
                        Event Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="status-group-label-recurring"
                        name="row-radio-buttons-group-recurring"
                        value={isRecurringEvent}
                        onChange={(e) =>
                          setIsRecurringEvent(e.target.value === "true" ? true : false)
                        }
                      >
                        <FormControlLabel
                          value={"false"}
                          control={<Radio />}
                          label="Single Event"
                        />
                        <FormControlLabel
                          value={"true"}
                          control={<Radio />}
                          label="Repeating Event"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
                {isRecurringEvent && (
                  <Grid item xs={12} display={"flex"} direction={"row"} gap={10} marginTop={2}>
                    <FormControl fullWidth required={isRecurringEvent}>
                      <InputLabel focused={true} id="day-of-week-select-label">
                        Recurrs every{" "}
                        {recurringDayOfWeek !== null
                          ? DayOfWeek[recurringDayOfWeek] +
                            " at " +
                            dayjs(event?.start).format("h:mm A")
                          : "[select day]"}
                      </InputLabel>
                      <Select
                        labelId="day-of-week-select-label"
                        id="day-of-week"
                        value={recurringDayOfWeek}
                        label="Recurrs on this day each week"
                        readOnly={true}
                      >
                        <MenuItem value={DayOfWeek.Sunday}>Sunday</MenuItem>
                        <MenuItem value={DayOfWeek.Monday}>Monday</MenuItem>
                        <MenuItem value={DayOfWeek.Tuesday}>Tuesday</MenuItem>
                        <MenuItem value={DayOfWeek.Wednesday}>Wednesday</MenuItem>
                        <MenuItem value={DayOfWeek.Thursday}>Thursday</MenuItem>
                        <MenuItem value={DayOfWeek.Friday}>Friday</MenuItem>
                        <MenuItem value={DayOfWeek.Saturday}>Saturday</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth required={isRecurringEvent}>
                      <InputLabel focused={false} id="number-of-weeks-input">
                        For this many weeks
                      </InputLabel>
                      <Select
                        labelId="number-of-weeks-input"
                        id="number-of-week"
                        value={numberOfWeeksToRepeat}
                        label="Recurrs on this day each week"
                        onChange={(e) => setNumberOfWeeksToRepeat(e.target.value as number)}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="title">Price</FormLabel>
                    <TextField
                      id="price"
                      value={event?.price || ""}
                      onChange={(e) => setEvent({ ...event, price: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="link">
                      Link
                      <a href={event?.link} target="_blank" rel="noreferrer">
                        <Icon>open_in_new</Icon>
                      </a>
                    </FormLabel>
                    <TextField
                      id="link"
                      value={event?.link || ""}
                      onChange={(e) => setEvent({ ...event, link: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="video">Video</FormLabel>
                    <Autocomplete
                      value={event?.video?.title || ""}
                      freeSolo
                      onChange={(e, value) =>
                        setEvent({
                          ...event,
                          video: videos?.find((video: any) => video.id === value),
                        })
                      }
                      options={videos?.map((video: any) => video.id)}
                      getOptionLabel={(option) =>
                        videos?.find((video: any) => video.id === option)?.title || option || ""
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="tags">Tags</FormLabel>
                    <Autocomplete
                      multiple
                      value={event?.tags?.map((tag: any) => tag.id) || []}
                      onChange={(_e, value) => {
                        setEvent({
                          ...event,
                          tagIds: value,
                          tags: tags.filter((tag: any) => value.includes(tag.id)),
                        });
                      }}
                      filterOptions={filterOptions}
                      options={tags.map((tag: any) => tag.id)}
                      getOptionLabel={renderTagsOptionLabel}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel id="status-group-label">Publishing Status</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="status-group-label"
                      name="row-radio-buttons-group"
                      value={event.status}
                      onChange={(e) => setEvent({ ...event, status: e.target.value as StatusEnum })}
                    >
                      <FormControlLabel
                        value={StatusEnum.Pending}
                        control={<Radio />}
                        label="Pending"
                      />
                      <FormControlLabel
                        value={StatusEnum.Published}
                        control={<Radio />}
                        label="Published"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} display={"flex"} gap={"10px"}>
                  <FormGroup>
                    <FormLabel id="status-group-label">Monitization Options</FormLabel>
                    <FormControlLabel
                      htmlFor="featured"
                      label={"Featured"}
                      control={
                        <Checkbox
                          checked={event?.featured || false}
                          onChange={(e) => setEvent({ ...event, featured: e.target.checked })}
                        />
                      }
                    />
                    <FormControlLabel
                      htmlFor="boosted"
                      label={"Boosted"}
                      control={
                        <Checkbox
                          checked={event?.boosted || false}
                          onChange={(e) => setEvent({ ...event, boosted: e.target.checked })}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel id="promotion">Current Promotion</FormLabel>
                  <PromotionSelect
                    value={event?.promotion}
                    onChange={(promotion: any) => setEvent({ ...event, promotion })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel id="status-group-label">Organization (optional)</FormLabel>
                  <OrganizationSelect
                    value={event?.organization}
                    onChange={(organization: any) => setEvent({ ...event, organization })}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  {event?.thumbnail ? (
                    <img src={event?.thumbnail} style={{ width: "100%" }} alt={"Event"} />
                  ) : (
                    <Icon
                      baseClassName="material-icons-outlined"
                      sx={{ fontSize: "80px !important", fontWeight: 100 }}
                    >
                      image
                    </Icon>
                  )}
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Button
                    size="large"
                    disabled={loading || !event.description}
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateClick}
                  >
                    Generate Image From Description
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel htmlFor="description">Images</FormLabel>
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={loading || contextLoading} />
      </Grid>
    </BasicLayout>
  );
};

export default Event;
