import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  InputAdornment,
  Snackbar,
  createFilterOptions,
} from "@mui/material";
import { DataGrid, GridColDef, GridExpandMoreIcon, GridToolbarContainer } from "@mui/x-data-grid";
import { debounce } from "lodash";
import TextField from "@mui/material/TextField";
import { IQueryObject, IService, IPaginationModal } from "types/App";
import { IFilterOptions } from "types/paginatedDataGrid";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ILocationData } from "types/locations.d";
import { ITagGroup } from "types/tagGroups";
import { ITag } from "types/tags";
import { capitalizeFirstLetter, getLocationById, getTagGroupById } from "utils/commonFunctions";
import { IEvent, StatusEnum } from "types/events.d";
import { useGlobalContext } from "context/GlobalContext";
import DeleteItemsModal from "./DeleteItemsModal";
import BulkEditItemsModal from "./BulkEditItemsModal";

interface IPaginatedDataGridProps {
  collectionName: string;
  columns?: GridColDef[];
  defaultSortField?: string;
  defaultSortDirection?: "asc" | "desc";
  service?: IService;
  filterOptions?: IFilterOptions;
}

interface IDataGridPageInfo {
  page: number;
  pageSize: number;
}

const tagFilterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 10,
});

const PaginatedDataGrid = (props: IPaginatedDataGridProps) => {
  const {
    collectionName,
    columns = [],
    defaultSortField = "title",
    defaultSortDirection = "asc",
    service,
    filterOptions,
  } = props;
  const {
    fetchDependencies,
    loading: contextLoading,
    locations,
    tags,
    tagGroups,
    events,
  } = useGlobalContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = React.useState<
    IPaginationModal & IDataGridPageInfo
  >({
    pageSize: 10,
    page: 0,
  });
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(defaultSortDirection);
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [authorSearch, setAuthorSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [debouncedCitySearch, setDebouncedCitySearch] = useState("");
  const [debouncedAuthorSearch, setDebouncedAuthorSearch] = useState("");
  const [formattedAddressSearch, setFormattedAddressSearch] = useState("");
  const [debouncedFormattedAddressSearch, setDebouncedFormattedAddressSearch] = useState("");
  const [fromDate, setFromDate] = useState<string | undefined>(undefined);
  const [toDate, setToDate] = useState<string | undefined>(undefined);
  const [selectedLocation, setSelectedLocation] = useState<ILocationData>(undefined);
  const [selectedTagGroup, setSelectedTagGroup] = useState<ITagGroup>(undefined);
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [boostedSelected, setBoostedSelected] = useState(false);
  const [featuredSelected, setFeaturedSelected] = useState(false);
  const [status, setStatus] = useState<StatusEnum>(undefined);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const location = useLocation();

  const getQueryParams = useCallback(() => {
    const queryParams: IQueryObject[] = [];

    if (debouncedSearch) {
      queryParams.push({
        field: filterOptions?.name ? "name" : "title",
        operator: "search",
        value: debouncedSearch,
      });
    }
    if (fromDate) {
      queryParams.push({
        field: "start",
        operator: "gte",
        value: fromDate,
      });
    }
    if (toDate) {
      queryParams.push({
        field: "start",
        operator: "lte",
        value: toDate,
      });
    }
    if (debouncedCitySearch) {
      queryParams.push({
        field: "location.city",
        operator: "eq",
        value: debouncedCitySearch,
      });
    }
    if (debouncedAuthorSearch) {
      queryParams.push({
        field: "author.name",
        operator: "eq",
        value: debouncedAuthorSearch,
      });
    }
    if (debouncedFormattedAddressSearch) {
      queryParams.push({
        field: "formattedAddress",
        operator: "search",
        value: debouncedFormattedAddressSearch,
      });
    }
    if (selectedTags.length) {
      const values = selectedTags.map((tag) => tag.id);
      queryParams.push({
        field: "tagIds",
        operator: "in",
        value: values,
      });
    }
    if (selectedLocation) {
      queryParams.push({
        field: "location._id",
        operator: "eq",
        value: selectedLocation.id,
      });
    }
    if (featuredSelected) {
      queryParams.push({
        field: "featured",
        operator: "eq",
        value: true,
      });
    }
    if (boostedSelected) {
      queryParams.push({
        field: "boosted",
        operator: "eq",
        value: true,
      });
    }
    if (status) {
      queryParams.push({
        field: "status",
        operator: "eq",
        value: status.toLowerCase(),
      });
    }

    return queryParams;
  }, [
    debouncedSearch,
    fromDate,
    toDate,
    debouncedCitySearch,
    debouncedAuthorSearch,
    debouncedFormattedAddressSearch,
    selectedTags,
    selectedLocation,
    featuredSelected,
    boostedSelected,
    status,
    filterOptions?.name,
  ]);

  // reset filters from state when navigating back
  useEffect(() => {
    if (collectionName === window.history.state?.collectionName) {
      setPaginationModel({
        ...paginationModel,
        page: window.history.state.page || 0,
        pageSize: window.history.state.pageSize || 10,
      });
    }
    if (
      collectionName === window.history.state?.collectionName &&
      window.history.state?.queryParams?.length > 0
    ) {
      setFilterExpanded(true);

      window.history.state.queryParams.forEach((param: IQueryObject) => {
        switch (param.field) {
          case "title":
          case "name":
            setSearch(param.value as string);
            setDebouncedSearch(param.value as string);
            break;

          case "start":
            if (param.operator === "gte") {
              setFromDate(param.value as string);
            } else {
              setToDate(param.value as string);
            }
            break;

          case "location.city":
            setCitySearch(param.value as string);
            setDebouncedCitySearch(param.value as string);
            break;

          case "author.name":
            setAuthorSearch(param.value as string);
            setDebouncedAuthorSearch(param.value as string);
            break;

          case "formattedAddress":
            setFormattedAddressSearch(param.value as string);
            setDebouncedFormattedAddressSearch(param.value as string);
            break;

          case "tagIds":
            setSelectedTags(tags.filter((tag) => (param.value as string[]).includes(tag.id)));
            break;

          case "location._id":
            setSelectedLocation(locations.find((location) => location.id === param.value));
            break;

          case "featured":
            setFeaturedSelected(param.value as boolean);
            break;

          case "boosted":
            setBoostedSelected(param.value as boolean);
            break;

          case "status":
            setStatus(
              StatusEnum[capitalizeFirstLetter(param.value as string) as keyof typeof StatusEnum]
            );
            break;
        }
      });
    } else {
      // if no filters were in state, this will trigger the fetchall call
      setInitialLoad(true);
    }
  }, [
    locations,
    tags,
    collectionName,
    getQueryParams,
    location.pathname,
    paginationModel.pageSize,
    service,
    sortDirection,
    sortField,
    paginationModel,
  ]);

  //search based fetch
  useEffect(() => {
    const fetchData = async () => {
      if (!window.history.state.queryParams || initialLoad) {
        try {
          setLoading(true);
          const response = await service.fetchAll({
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sort: {
              [sortField]: sortDirection,
            },
            query: getQueryParams(),
          });
          setGridData(response.data);
          setRowCount(response.total);
        } catch (error) {
          console.error(`Error fetching ${collectionName}`, error);
        } finally {
          setLoading(false);
        }
      } else {
        window.history.replaceState({}, "", location.pathname);
      }
    };
    fetchData();
  }, [
    collectionName,
    getQueryParams,
    initialLoad,
    location.pathname,
    paginationModel.pageSize,
    paginationModel.page,
    service,
    sortDirection,
    sortField,
  ]);

  useEffect(() => {
    if (filterOptions?.tag && tags) {
      setFilteredTags(tags);
    }
  }, [filterOptions, tags]);

  const onDeleteSuccess = (id: string) => {
    setSelectedRowIds((prev) => prev.filter((item) => item !== id));
  };

  // Function to delete an event
  const handleDelete = useCallback(
    async ({ idArray, onSuccess }: { idArray: string[]; onSuccess?: (id: string) => void }) => {
      setLoading(true);
      const deleteActions = idArray.map(async (id) => {
        try {
          await service.remove(id);
          setGridData((prev) =>
            prev.filter((d: any) => {
              return d.id !== id;
            })
          );
          onSuccess && onSuccess(id);
        } catch (error: any) {
          if (error.response?.data) {
            setSnackbar({ open: true, message: error.response.data, severity: "error" });
          }
          console.error("Error deleting:", error);
        }
      });
      await Promise.allSettled(deleteActions).then(() => {
        setLoading(false);
        //update global context
        fetchDependencies();
      });
    },
    [fetchDependencies, service]
  );
  // Function to delete an event
  const handleBulkEdit = useCallback(
    async ({
      idArray,
      editedEvent,
      onSuccess,
    }: {
      idArray: string[];
      editedEvent: Partial<IEvent>;
      onSuccess?: (id: string) => void;
    }) => {
      setLoading(true);

      const newEventFields = { ...editedEvent };

      //remove undefined fields
      const updatedFields = Object.entries(newEventFields);
      for (const [key, value] of updatedFields) {
        if (value === undefined) {
          delete newEventFields[key as keyof typeof newEventFields];
        }
      }

      const editActions = idArray.map(async (id) => {
        try {
          const foundEvent = events.find((event) => event.id === id);
          if (foundEvent) {
            const updatedEvent = { ...foundEvent, ...newEventFields };
            await service.update(id, updatedEvent).then((res) => {
              setGridData((prev) => [
                ...prev.filter((d: any) => {
                  return d.id !== id;
                }),
                { ...res, id: res._id },
              ]);
            });

            onSuccess && onSuccess(id);
          }
        } catch (error: any) {
          if (error.response?.data) {
            setSnackbar({ open: true, message: error.response.data, severity: "error" });
          }
          console.error("Error deleting:", error);
        }
      });
      await Promise.allSettled(editActions).then(() => {
        setLoading(false);
        //update global context
        fetchDependencies();
      });
    },
    [events, fetchDependencies, service]
  );

  const _columns = useMemo(() => {
    return [
      ...columns,
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params: any) => (
          <>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              color="primary"
              //replace=true will persist the new state on navigating back

              onClick={() => {
                window.history.replaceState(
                  {
                    collectionName: collectionName,
                    queryParams: getQueryParams(),
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                  },
                  "",
                  location.pathname
                );
                navigate(
                  { pathname: `/${collectionName}/${params?.id}` },
                  {
                    state: { collectionName },
                    replace: false,
                  }
                );
              }}
            >
              Edit
            </Button>
            <DeleteItemsModal
              handleDelete={() =>
                handleDelete({ idArray: [params?.id], onSuccess: onDeleteSuccess })
              }
              loading={loading}
              selectedRowIds={undefined}
              doNotDisable={true}
              text="Delete"
            />
          </>
        ),
      },
    ];
  }, [
    columns,
    loading,
    collectionName,
    getQueryParams,
    paginationModel.page,
    paginationModel.pageSize,
    location.pathname,
    navigate,
    handleDelete,
  ]);

  // Debounce the search input
  const debounceSearch = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedSearch(value);
        setPaginationModel({ ...paginationModel, page: 0 });
      }, 700),
    [paginationModel]
  );

  // Debounce the author search input
  const debounceAuthorSearch = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedAuthorSearch(value);
        setPaginationModel({ ...paginationModel, page: 0 });
      }, 700),
    [paginationModel]
  );

  // Debounce the city search input
  const debounceCitySearch = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedCitySearch(value);
        setPaginationModel({ ...paginationModel, page: 0 });
      }, 700),
    [paginationModel]
  );

  // Debounce the formattedAddress search input
  const debounceFormattedAddressSearch = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedFormattedAddressSearch(value);
        setPaginationModel({ ...paginationModel, page: 0 });
      }, 700),
    [paginationModel]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
    debounceSearch(value);
  };

  const handleCitySearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCitySearch(value);
    debounceCitySearch(value);
  };

  const handleAuthorSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAuthorSearch(value);
    debounceAuthorSearch(value);
  };

  const handleFormattedAddressSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormattedAddressSearch(value);
    debounceFormattedAddressSearch(value);
  };

  const handlePageIndexing = (data: IDataGridPageInfo) => {
    setPaginationModel({ ...paginationModel, page: data.page, pageSize: data.pageSize });
  };

  const handleClearSearch = () => {
    setSearch("");
    debounceSearch("");
  };

  const handleClearCitySearch = () => {
    setCitySearch("");
    debounceCitySearch("");
  };

  const handleClearAuthorSearch = () => {
    setAuthorSearch("");
    debounceAuthorSearch("");
  };

  const handleClearFormattedAddressSearch = () => {
    setFormattedAddressSearch("");
    debounceFormattedAddressSearch("");
  };

  const renderTagsOptionLabel = (option: string) => {
    const tag = tags.find((tag) => tag.id === option);
    if (tag) return tag?.title;
    return "";
  };

  const updateFilteredTags = (tagGroupId: string) => {
    if (!tagGroupId) {
      setFilteredTags(tags || []);
      return;
    }
    const newFilteredTags: ITag[] = getTagGroupById({
      selectedTagGroupId: tagGroupId,
      tagGroups: tagGroups,
    })?.tags as ITag[];
    setFilteredTags(newFilteredTags || []);
    setSelectedTags(newFilteredTags || []);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DeleteItemsModal
          handleDelete={() => handleDelete({ idArray: selectedRowIds, onSuccess: onDeleteSuccess })}
          loading={loading}
          selectedRowIds={selectedRowIds}
        />
        {collectionName === "events" && (
          <BulkEditItemsModal
            handleEdit={(event) =>
              handleBulkEdit({
                idArray: selectedRowIds,
                editedEvent: event,
              })
            }
            selectedRowIds={selectedRowIds}
          />
        )}
      </GridToolbarContainer>
    );
  }

  const showSearch = filterOptions?.name || filterOptions?.title;
  return (
    <Grid container spacing={2}>
      {filterOptions && (
        <Accordion
          expanded={filterExpanded}
          onChange={(_e, expanded) => setFilterExpanded(expanded)}
          style={{ marginLeft: "16px", width: "100%" }}
        >
          <AccordionSummary
            id="panel-header"
            aria-controls="panel-content"
            expandIcon={<GridExpandMoreIcon />}
          >
            Filters
          </AccordionSummary>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
            {filterOptions && (
              <Grid container item>
                <Grid
                  id="filter-box"
                  container
                  border={"solid 1px #e0e0e0"}
                  padding={"10px"}
                  margin={0}
                  spacing={2}
                >
                  {showSearch && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="grid-search">
                          {filterOptions?.name ? "Name" : "Title"}
                        </FormLabel>
                        <TextField
                          type="text"
                          name="search"
                          id="grid-search"
                          value={search}
                          placeholder={filterOptions?.name ? "Name Search" : "Title Search"}
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>search</Icon>
                              </InputAdornment>
                            ),
                            ...(search
                              ? {
                                  endAdornment: (
                                    <InputAdornment style={{ cursor: "pointer" }} position="end">
                                      <Icon onClick={handleClearSearch}>close</Icon>
                                    </InputAdornment>
                                  ),
                                }
                              : {}),
                          }}
                          onChange={handleSearchChange}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions.author && (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="author-search">Author</FormLabel>
                        <TextField
                          type="text"
                          name="author-search"
                          id="author-search"
                          value={authorSearch}
                          placeholder="Author Search"
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>search</Icon>
                              </InputAdornment>
                            ),
                            ...(authorSearch
                              ? {
                                  endAdornment: (
                                    <InputAdornment style={{ cursor: "pointer" }} position="end">
                                      <Icon onClick={handleClearAuthorSearch}>close</Icon>
                                    </InputAdornment>
                                  ),
                                }
                              : {}),
                          }}
                          onChange={handleAuthorSearchChange}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions.city && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="citySearch">City</FormLabel>
                        <TextField
                          type="text"
                          name="city-search"
                          id="city-search"
                          value={citySearch}
                          placeholder="City Search"
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>search</Icon>
                              </InputAdornment>
                            ),
                            ...(citySearch
                              ? {
                                  endAdornment: (
                                    <InputAdornment style={{ cursor: "pointer" }} position="end">
                                      <Icon onClick={handleClearCitySearch}>close</Icon>
                                    </InputAdornment>
                                  ),
                                }
                              : {}),
                          }}
                          onChange={handleCitySearchChange}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions?.dateRange && (
                    <Grid container item xs={12} md={4} spacing={2} direction={"row"}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="from-search">From Date</FormLabel>
                          <DateTimePicker
                            slotProps={{ actionBar: { actions: ["clear", "accept"] } }}
                            name={"from-search"}
                            value={fromDate ? dayjs(fromDate) : null}
                            maxDate={toDate ? dayjs(toDate) : undefined}
                            onAccept={(value: any) => {
                              setFromDate(value?.toDate()?.toISOString());
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="to-search">To Date</FormLabel>
                          <DateTimePicker
                            slotProps={{ actionBar: { actions: ["clear", "accept"] } }}
                            minDateTime={fromDate ? dayjs(fromDate) : undefined}
                            name={"to-search"}
                            value={toDate ? dayjs(toDate) : null}
                            onAccept={(value: any) => {
                              setToDate(value?.toDate()?.toISOString());
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  {filterOptions.formattedAddress && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="address-search">Address</FormLabel>
                        <TextField
                          type="text"
                          name="address-search"
                          id="address-search"
                          value={formattedAddressSearch}
                          placeholder="Address Search"
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>search</Icon>
                              </InputAdornment>
                            ),
                            ...(formattedAddressSearch
                              ? {
                                  endAdornment: (
                                    <InputAdornment style={{ cursor: "pointer" }} position="end">
                                      <Icon onClick={handleClearFormattedAddressSearch}>close</Icon>
                                    </InputAdornment>
                                  ),
                                }
                              : {}),
                          }}
                          onChange={handleFormattedAddressSearchChange}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions?.tagGroup && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="tagGroup">Tag Group</FormLabel>
                        <Autocomplete
                          id="tagGroup"
                          value={selectedTagGroup?.title || ""}
                          onChange={(e, value) => {
                            setSelectedTagGroup(
                              getTagGroupById({
                                selectedTagGroupId: value,
                                tagGroups: tagGroups,
                              })
                            );
                            updateFilteredTags(value);
                          }}
                          freeSolo
                          options={tagGroups?.map((tagGroup: any) => tagGroup.id)}
                          getOptionLabel={(option) =>
                            tagGroups.find((tagGroup: ITagGroup) => tagGroup.id === option)
                              ?.title || option
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions?.tag && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="tag">Tag</FormLabel>
                        <Autocomplete
                          multiple
                          id="tag"
                          value={selectedTags?.map((tag: any) => tag.id) || []}
                          onChange={(e, value) =>
                            setSelectedTags(
                              filteredTags.filter((tag: any) => value.includes(tag.id))
                            )
                          }
                          filterOptions={tagFilterOptions}
                          options={filteredTags?.map((tag: any) => tag.id)}
                          getOptionLabel={renderTagsOptionLabel}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions?.location && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="location">Location</FormLabel>
                        <Autocomplete
                          value={selectedLocation?.name || ""}
                          onChange={(e, value) =>
                            setSelectedLocation(getLocationById({ locationId: value, locations }))
                          }
                          freeSolo
                          options={locations?.map((location: any) => location.id)}
                          getOptionLabel={(option) =>
                            locations.find((location: any) => location.id === option)?.name ||
                            option
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions?.status && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="location">Published Status</FormLabel>
                        <Autocomplete
                          value={status || ""}
                          onChange={(e, value) => setStatus(value as StatusEnum)}
                          freeSolo
                          options={(Object.keys(StatusEnum) as Array<keyof typeof StatusEnum>).map(
                            (key) => key
                          )}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {filterOptions.featured && (
                    <Grid
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={4}
                      gap={"5px"}
                      marginTop={"25px"}
                    >
                      <input
                        type="checkbox"
                        checked={featuredSelected || false}
                        onChange={(e) => setFeaturedSelected(e.target.checked)}
                        name="featured"
                        id="featured"
                      />
                      <span> </span>
                      <label htmlFor="featured">Featured</label>
                    </Grid>
                  )}
                  {filterOptions.boosted && (
                    <Grid
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={4}
                      gap={"5px"}
                      marginTop={"25px"}
                    >
                      <input
                        type="checkbox"
                        checked={boostedSelected || false}
                        onChange={(e) => setBoostedSelected(e.target.checked)}
                        name="boosted"
                        id="boosted"
                      />
                      <span> </span>
                      <label htmlFor="boosted">Boosted</label>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Accordion>
      )}
      <Grid item xs={12}>
        <DataGrid
          loading={loading || contextLoading}
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            minWidth: "400px",
          }}
          autoHeight
          rows={gridData}
          rowCount={rowCount}
          getRowId={(row) => row.id || row._id}
          columns={_columns}
          pageSizeOptions={[2, 10, 25, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          paginationModel={paginationModel}
          onRowSelectionModelChange={(data: any) => setSelectedRowIds(data)}
          rowSelectionModel={selectedRowIds}
          onPaginationModelChange={(data: IDataGridPageInfo) => {
            handlePageIndexing(data);
          }}
          onSortModelChange={(data: any) => {
            setSortField(data[0]?.field);
            setSortDirection(data[0]?.sort);
          }}
          paginationMode="server"
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Grid>

      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false, message: "" })}
      >
        <Alert
          severity={snackbar.severity}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setSnackbar({ ...snackbar, open: false, message: "" })}
            >
              Dismiss
            </Button>
          }
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default PaginatedDataGrid;
