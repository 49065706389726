import { useState, ReactNode } from "react";
import { Link } from "react-router-dom";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import Divider from "@mui/material/Divider";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavList from "components/Sidenav/SidenavList";
import SidenavItem from "components/Sidenav/SidenavItem";

import { AppBar, Box, Drawer, useMediaQuery } from "@mui/material";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import theme from "assets/theme";

import Logo from "assets/images/logos/okaynwa_logo.svg";

import routes from "../../routes";
import { useGlobalContext } from "context/GlobalContext";

// Declaring props types for Sidenav
interface Props {
  children?: ReactNode;
}

const Sidenav: React.FC<Props> = ({ children }: Props) => {
  const { openCollapse, setOpenCollapse } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const drawerWidth = isXLScreen || isOpen ? 240 : 0;
  const handleDrawerClose = () => {
    setIsClosing(true);
    setIsOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const renderNestedCollapse = (collapse: any) => {
    const template = collapse.map(({ name, route, key, href }: any) =>
      href ? (
        <Link
          key={key}
          to={href}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setIsOpen(!isOpen);
    }
  };

  const renderCollapse = (collapses: any) =>
    collapses
      .filter(({ type }: any) => type !== "hidden")
      .sort((a: any, b: any) => {
        return a.name - b.name;
      })
      .map(({ name, collapse, route, href, key, type }: any) => {
        let returnValue;

        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              color={"info"}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }: any) =>
                openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          returnValue = href ? (
            <Link
              to={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <SidenavItem color={"info"} name={name} active={key === itemName} />
            </Link>
          ) : (
            <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
              <SidenavItem color={"info"} name={name} active={key === itemName} />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      });

  const drawer = routes
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    })
    .map(({ type, name, icon, title, collapse, noCollapse, key, href, route, hidden }: any) => {
      let returnValue;
      if (type === "collapse") {
        returnValue = (
          <SidenavCollapse
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            open={openCollapse === key}
            onClick={() => (openCollapse === key ? setOpenCollapse(null) : setOpenCollapse(key))}
          >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      } else if (type === "title") {
        returnValue = (
          <Link
            to="/"
            key={key}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              key={"title"}
              src={Logo}
              width={150}
              alt={"logo"}
              style={{
                alignSelf: "center",
                display: "flex",
                marginTop: " 15px",
              }}
            />
          </Link>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} light={true} />;
      } else if (type !== "hidden" && route) {
        returnValue = (
          <MDBox
            key={key}
            style={{ padding: "0px 20px" }}
            onClick={() => isSmallScreen && handleDrawerToggle()}
          >
            <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
              <SidenavItem color={"info"} name={name} active={key === itemName} icon={icon} />
            </NavLink>
          </MDBox>
        );
      }

      return returnValue;
    });
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `100% ` },
        }}
      >
        <DashboardNavbar handleDrawerToggle={handleDrawerToggle} isOpen={isOpen} />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {isSmallScreen ? (
          <Drawer
            variant="temporary"
            open={isOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              backgroundColor: "#091c0e",
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                borderRadius: 0,
                margin: 0,
                width: drawerWidth,
                backgroundColor: "#091c0e",
                overflowX: "hidden",
                height: "100%",
              },
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#091c0e",
                overflowX: "hidden",
              },
              "& .MuiCollapse-entered": {
                minHeight: "fit-content !important",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        )}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          margin: `${isSmallScreen ? 0 : "40px 20px 20px 20px"}`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Sidenav;
