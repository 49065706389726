import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import eventsService from "services/events";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import { capitalizeFirstLetter } from "utils/commonFunctions";

const Events: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "thumbnail",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value} alt="Event" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 2, minWidth: 200 },
    {
      field: "location",
      headerName: "Location",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => <span>{params?.value?.name}</span>,
    },
    {
      field: "start",
      headerName: "Start",
      minWidth: 200,
      renderCell: (params: any) => {
        const d = new Date(params?.value);
        return <span>{d.toLocaleString()}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => <>{capitalizeFirstLetter(params?.value)}</>,
    },
  ];

  const filterOptions: IFilterOptions = {
    title: true,
    name: false,
    dateRange: true,
    city: true,
    tagGroup: true,
    tag: true,
    location: true,
    featured: true,
    boosted: true,
    status: true,
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Events</Typography>
        </Grid>
        <Grid item xs={2} sm={4}></Grid>
        <Grid item xs={6} sm={4} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/events/create">
            <Icon>add</Icon>
            Create Event
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="events"
              defaultSortField="start"
              defaultSortDirection="desc"
              columns={columns}
              service={eventsService}
              filterOptions={filterOptions}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Events;
