import {
  Autocomplete,
  createFilterOptions,
  FormLabel,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
} from "@mui/material";
import { INews } from "types/news";

import { useGlobalContext } from "context/GlobalContext";
import GenerateNewImageModal, {
  ImageSource,
} from "components/GenerateNewImageModal/GenerateNewImageModal";
import { StatusEnum } from "types/events.d";
import PreviewArticleModal from "./PreviewArticleModal";

const NewsArticle = ({
  news,
  setNews,
}: {
  news: Partial<INews>;
  setNews: (news: Partial<INews>) => void;
}) => {
  const { tags, tagGroups } = useGlobalContext();

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  return (
    <Grid container className="NewsArticle" spacing={2}>
      <Grid item xs={12} className="NewsArticle__header">
        <Typography variant="h4">Article</Typography>
      </Grid>
      <Grid item xs={12} sm={3} className="NewsArticle__image">
        {news?.image && (
          <>
            <img src={news?.image} alt={news.title} style={{ width: "100%" }} />
            <Grid item xs={12} display="flex" justifyContent="center">
              <GenerateNewImageModal
                onAccept={(url) => setNews({ ...news, image: url })}
                currentPrompt={news.article || news.prompt}
                source={ImageSource.news}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={9} className="NewsArticle__image">
        <Grid container spacing={2}>
          <Grid item xs={12} className="NewsArticle__header">
            <FormControl fullWidth>
              <Typography variant="h6">Title</Typography>
              <TextField
                id="title"
                value={news?.title || ""}
                onChange={(e: any) => setNews({ ...news, title: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="NewsArticle__header">
            <FormControl fullWidth>
              <Typography variant="h6">Subtitle</Typography>
              <TextField
                id="subtitle"
                value={news?.subtitle || ""}
                onChange={(e: any) => setNews({ ...news, subtitle: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className="NewsArticle__summary">
            <FormControl fullWidth>
              <Typography variant="h6">Summary</Typography>
              <TextField
                id="summary"
                multiline
                rows={4}
                value={news?.summary || ""}
                onChange={(e: any) => setNews({ ...news, summary: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} className="NewsArticle__content">
            <FormControl fullWidth>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                marginBottom={2}
                alignItems={"flex-end"}
              >
                <Typography variant="h6">Content (HTML)</Typography>
                {news.content && <PreviewArticleModal news={news} />}
              </Grid>
              <TextField
                id="content"
                multiline
                rows={20}
                value={news?.content || ""}
                onChange={(e: any) => setNews({ ...news, content: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className="NewsArticle__author">
            <InputLabel>Author</InputLabel>
            <TextField id="author" disabled value={news?.author?.name || ""} />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="tags">Tags</FormLabel>
              <Autocomplete
                multiple
                value={news?.tags?.map((tag: any) => tag.id) || []}
                onChange={(_e, value) => {
                  setNews({
                    ...news,
                    tagIds: value,
                    tags: tags.filter((tag: any) => value.includes(tag.id)),
                  });
                }}
                filterOptions={filterOptions}
                options={tags.map((tag: any) => tag.id)}
                getOptionLabel={(option: any) => {
                  const tag = tags.find((tag: any) => tag.id === option);
                  const tagGroup = tagGroups.find((tagGroup: any) =>
                    tagGroup.tags.some((t: any) => t?.id === tag?.id)
                  );
                  if (tag) {
                    if (tagGroup) {
                      return `${tag.title} - ${tagGroup.title}`;
                    }
                    return tag?.title;
                  }
                  return option;
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={news?.featured || false}
                  onChange={(e: any) => setNews({ ...news, featured: e.target.checked })}
                />
              }
              label="Featured"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={news?.trending || false}
                  onChange={(e: any) => setNews({ ...news, trending: e.target.checked })}
                />
              }
              label="Trending"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormLabel id="status-group-label">Publishing Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="status-group-label"
                name="row-radio-buttons-group"
                value={news?.status}
                onChange={(e) => setNews({ ...news, status: e.target.value as StatusEnum })}
              >
                <FormControlLabel value={StatusEnum.Pending} control={<Radio />} label="Pending" />
                <FormControlLabel
                  value={StatusEnum.Published}
                  control={<Radio />}
                  label="Published"
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          {news?.video?.url && <Grid item xs={12}>
            <Typography variant="h6">Video</Typography>
            <video controls width="600">
              <source src={news?.video?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            </Grid>}

        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsArticle;
