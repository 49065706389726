import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import LoadingOverlay from "components/LoadingOverlay";
import React from "react";
import imageService from "services/images";

export enum ImageSource {
  "news" = "news",
  "tag" = "tag",
  "tagGroup" = "tagGroup",
}

const DEFAULT_TEXT = "The image should";

interface GenerateNewImageModalProps {
  onAccept: (imageUrl: string) => void;
  currentPrompt?: string;
  bucketPath?: string;
  source?: ImageSource;
}

const GenerateNewImageModal = (props: GenerateNewImageModalProps) => {
  const { onAccept, currentPrompt = "", bucketPath = "okaynwa/news", source = undefined } = props;

  const [generatedImageUrl, setGeneratedImageUrl] = React.useState<string | undefined>(undefined);
  const [newImageModalOpen, setNewImageModalOpen] = React.useState(false);
  const [newPrompt, setNewPrompt] = React.useState<string | undefined>(DEFAULT_TEXT);
  //   const [includeSeedPrompt, setIncludeSeedPrompt] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const handleModalCancel = () => {
    setNewImageModalOpen(false);
    setNewPrompt(DEFAULT_TEXT);
    setGeneratedImageUrl(undefined);
  };

  const generatePrompt = () => {
    let generationPrompt = "";
    const specialInstructions = newPrompt !== DEFAULT_TEXT ? newPrompt : "";
    if (source === ImageSource.news) {
      generationPrompt +=
        "From the following article, generate an image that represents the article. " +
        specialInstructions +
        " The image should be 1024x1024: " +
        currentPrompt;
    } else {
      generationPrompt += specialInstructions + " The image should be 1024x1024: ";
    }
    return generationPrompt;
  };

  const handleGenerateClick = async () => {
    try {
      setLoading(true);
      if (!newPrompt) {
        alert("Please fill in the prompt");
        throw new Error("Please fill in all fields");
      }

      // Use openai to generate the news article
      const response = await imageService.generate({
        prompt: generatePrompt(),
        path: bucketPath,
      });
      setGeneratedImageUrl(response.data.image);
    } catch (error: any) {
      console.error("Error generating news:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        size="large"
        // disabled={!news.seedImageURL && !news.image}
        variant="contained"
        color="primary"
        onClick={() => setNewImageModalOpen(true)}
      >
        Generate new image
      </Button>
      <Dialog fullWidth open={newImageModalOpen} onClose={() => setNewImageModalOpen(false)}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Generate a new AI image
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleModalCancel();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <DialogContent dividers>
          <Grid container item spacing={2} direction={"row"} width={"100%"}>
            <Grid
              item
              xs={6}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              direction={"column"}
              display={"flex"}
              height={"100%"}
            >
              <FormLabel id="prompt-selector">
                {source === ImageSource.news ? "Special Instructions" : "Image Prompt"}
              </FormLabel>
              <FormControl fullWidth>
                <TextareaAutosize
                  id="new-prompt"
                  value={newPrompt || ""}
                  maxRows={9}
                  minRows={9}
                  placeholder="The image should..."
                  style={{
                    resize: "none",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    padding: "5px",
                  }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    let initialText = "";
                    if (!newPrompt) {
                      initialText += "The image should ";
                    }
                    return setNewPrompt(initialText + e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              justifyContent={"center"}
              display={"flex"}
              width={"100%"}
              direction={"column"}
              height={"100%"}
            >
              <FormLabel id="seed-image-selector">Generated Image</FormLabel>
              {generatedImageUrl ? (
                <img
                  src={generatedImageUrl}
                  alt={"Inspiration for AI"}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "150px",
                    objectFit: "cover",
                    margin: "4px",
                  }}
                />
              ) : (
                <Icon
                  sx={{
                    fontSize: "100px !important",
                    fontWeight: 100,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  image
                </Icon>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item justifyContent={"flex-end"} spacing={4}>
            <Grid item>
              <Button variant="contained" color="error" onClick={handleModalCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => handleGenerateClick()}>
                Generate New Image
              </Button>
            </Grid>
            {generatedImageUrl && (
              <Grid item>
                <Button
                  disabled={!generatedImageUrl}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onAccept(generatedImageUrl);
                    setNewImageModalOpen(false);
                  }}
                >
                  Use this Image
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
        <LoadingOverlay loading={loading} />
      </Dialog>
    </>
  );
};

export default GenerateNewImageModal;
