import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import authorsService from "../../services/authors";
import { IAuthor } from "types/authors";

interface Author {
  id: number;
  name: string;
}

interface AuthorSelectProps {
  value?: Author;
  onChange: (author: Author) => void;
}

const AuthorSelect: React.FC<AuthorSelectProps> = ({ onChange, value }) => {
  const [authors, setAuthors] = useState<Author[]>([]);

  useEffect(() => {
    const fetchAuthors = async () => {
      const authors = await authorsService.fetchAll({ pageSize: 1000 });
      setAuthors(authors.data);
    };
    fetchAuthors();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedAuthor = authors.find((author) => author.id === value);
    if (selectedAuthor) {
      onChange(selectedAuthor);
    }
  };

  return (
    <Autocomplete
      value={value?.id}
      onChange={(e, value) => handleSelectChange(value)}
      options={authors?.map((author: any) => author.id)}
      getOptionLabel={(option) =>
        authors.find((author: any) => author.id === option)?.name || option
      }
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default AuthorSelect;
