import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import authorsService from "services/authors";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";

const Authors: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "author_img",
      headerName: "",
      flex: 1,
      renderCell: (params: any) => (
        <img src={params?.value} alt="Event" style={{ width: "50px" }} />
      ),
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    { field: "funTitle", headerName: "Fun Title", flex: 1 },
  ];

  const filterOptions: IFilterOptions = {
    name: true,
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Authors</Typography>
        </Grid>
        <Grid item xs={12} sm={8} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/authors/create">
            <Icon>add</Icon>
            Create New Author
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PaginatedDataGrid
            collectionName="authors"
            defaultSortField="title"
            defaultSortDirection="desc"
            columns={columns}
            service={authorsService}
            filterOptions={filterOptions}
          />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Authors;
