import styled from "@emotion/styled";
import { Box, Icon, IconButton } from "@mui/material";
import { ITag } from "types/tags";

export const TagGrid = styled.div`
  width: 100%;
  display: grid;
  & div:first-of-type {
    border-top: none;
  }
`;
export const TagRow = styled.div`
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 10px;
  align-items: center;
`;
export const TagRowHeader = styled.div`
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  width: 100%;
  padding: 10px;
`;
export const HeaderTitle = styled.span`
  font-weight: 600;
`;

export const renderHeader = () => {
  return (
    <TagRowHeader>
      <Box gridColumn="1/2">
        <span />
      </Box>
      <Box gridColumn="span 3">
        <HeaderTitle>Tag Name</HeaderTitle>
      </Box>
      <Box gridColumn="span 5">
        <HeaderTitle>Tag Description</HeaderTitle>
      </Box>
      <Box gridColumn="span 2">
        <HeaderTitle>Actions</HeaderTitle>
      </Box>
    </TagRowHeader>
  );
};

export const renderTagRow = (
  tag: ITag,
  index: number,
  moveTag: ({ index, newIndex }: { index: number; newIndex: number }) => void,
  handleRemoveTag: (tag: ITag) => void
) => {
  return (
    <TagRow key={tag.id}>
      <Box gridColumn="1/2" alignItems={"center"} justifyContent={"center"} display={"flex"}>
        {tag?.thumbnail ? (
          <img
            src={tag?.thumbnail}
            style={{ height: "50px", width: "50px", objectFit: "cover" }}
            alt={"tag" + tag.title}
          />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        )}
      </Box>
      <Box gridColumn="span 3">
        <span>{tag.title}</span>
      </Box>
      <Box gridColumn="span 5">
        <span>{tag.description}</span>
      </Box>
      <Box gridColumn="span 2" gap={1} display="flex">
        <IconButton
          style={{ border: "solid 1px #9d9d9d", borderRadius: 0, padding: "3px" }}
          size="large"
          onClick={() => moveTag({ index, newIndex: index - 1 })}
        >
          <Icon>keyboard_arrow_up</Icon>
        </IconButton>
        <IconButton
          style={{ border: "solid 1px #9d9d9d", borderRadius: 0, padding: "3px" }}
          size="large"
          onClick={() => moveTag({ index, newIndex: index + 1 })}
        >
          <Icon>keyboard_arrow_down</Icon>
        </IconButton>
        <IconButton
          style={{ border: "solid 1px #9d9d9d", borderRadius: 0, padding: "3px" }}
          size="medium"
          color="error"
          onClick={() => handleRemoveTag(tag)}
        >
          <Icon>delete</Icon>
        </IconButton>
      </Box>
    </TagRow>
  );
};
