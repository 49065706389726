import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  Alert,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import utilsService from "services/utils";
import Thumbnail from "components/Thumbnail";
import LocationSelect from "components/LocationSelect";
import TagMultiSelect from "components/TagMultiSelect";
import VideoSelect from "components/VideoSelect";

import eventsService from "services/events";
// alert icon
import { Edit, Warning } from "@mui/icons-material";
import EventEditDialog from "components/EventEditDialog";
import { useDropzone } from "react-dropzone";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";

// const exampleResponse = [
//   {
//     "id": "123234",
//     "title": "Nuclear Victims' Remembrance Day Commemoration",
//     "start": "2024-03-01T18:00:00",
//     "end": "2024-03-01T20:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/Image-2-27-24-at-8.49-AM-1.jpg",
//     "description": "<p>The Marshallese Educational Initiative, a Springdale based nonprofit, hosts Nuclear Legacy Week 2024 (NLW24), to bring Marshallese community members together with other frontline, nuclear affected community members from around the globe to share stories and experiences and educate about the consequences of nuclear weapons’ use, testing, uranium mining and milling, and waste storage.&nbsp;</p>\n<p>Join us on March 1 at the Jones Center for Nuclear Victims' Remembrance Day commemoration. Details here .</p>\n<p>Hosted by the Marshallese Educational Initiative.</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/nuclear-victims-remembrance-day-commemoration-5957"
//   },
//   {
//     "id": "999123235",
//     "title": "Springdale Bike Club Taco Tuesday Ride",
//     "start": "2024-03-05T19:00:00",
//     "end": "2024-03-05T21:00:00",
//     "image": "https://calsaas-production.s3.amazonaws.com/uploads/sites/10310/2024/02/Taco-Tuesday-7.png",
//     "description": "<p>Taco Tuesday rides are every Tuesday. Riders depart from Shiloh Square at 7PM, ride 12 miles at an avg pace of 11-14 mph, traveling over mostly Greenway and protected bike lane. Following the ride, the Club meets up at Mr Taco Loco for tacos &amp; drinks.&nbsp;</p>\n<p>Check their<a href=\"https://www.facebook.com/groups/springdalebikeclub/\" target=\"_self\" style=\"\" rel=\"noopener\"> FB group</a> for updates and weather cancellations.</p>",
//     "price": "",
//     "link": "https://downtown-springdale.loxi.io/springdale-bike-club-taco-tuesday-ride-5966"
//   },
// ];
const EventsFromImage: React.FC = () => {
  const [prompt, setPrompt] = React.useState(null);
  const [metadata, setMetadata] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);

  const [field, setField] = React.useState<string>("");
  const [value, setValue] = React.useState<any>(null);

  const [events, setEvents] = React.useState<any[]>([]);
  const [editing, setEditing] = React.useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "thumbnail",
      headerName: "",
      width: 100,
      renderCell: (params: any) => (
        <img src={params?.value} alt="Event" style={{ width: "50px" }} />
      ),
    },
    { field: "status", headerName: "Status", width: 100 },
    { field: "title", headerName: "Title", width: 400 },
    {
      field: "start",
      headerName: "Start",
      width: 200,
      renderCell: (params: any) => {
        const d = new Date(params?.value);
        return <span>{d.toLocaleString()}</span>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 300,
      renderCell: (params: any) => <span>{params?.value?.name}</span>,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "link",
      headerName: "Link",
      width: 400,
      renderCell: (params: any) => {
        if(!params?.value) return null;
        return (
          <Link to={params?.value} target="_blank">
            {params?.value}
          </Link>
        );
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 400,
      renderCell: (params: any) => (
        <FormControl fullWidth>
          <TagMultiSelect
            selectedTags={params?.value}
            setSelectedTags={(selectedTags: any) => {
              setEvents((prevEvents) =>
                prevEvents.map((e) => {
                  if (e.id === params.row.id) {
                    return { ...e, tags: selectedTags };
                  }
                  return e;
                })
              );
            }}
          />
        </FormControl>
      ),
    },
    {
      field: "video",
      headerName: "Video",
      width: 300,
      renderCell: (params: any) => (
        <FormControl fullWidth>
          <VideoSelect
            value={params?.value}
            onChange={(video) => {
              setEvents((prevEvents) =>
                prevEvents.map((e) => {
                  if (e.id === params.row.id) {
                    return { ...e, video };
                  }
                  return e;
                })
              );
            }}
          />
        </FormControl>
      ),
    },
    {
      field: "id",
      headerName: "Remove?",
      width: 100,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={() => {
              setEditing(params.row);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              setEvents((prevEvents) => prevEvents.filter((e) => e.id !== params?.value));
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </>
      ),
    },
  ];

  const onDrop = async (acceptedFiles: any) => {
    try {
      setLoading(true);
      if (acceptedFiles.length === 0) return;
      const file = acceptedFiles[0];
      setFile(file);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleAnalyze = async () => {
    try {
      setLoading(true);
      const data = await utilsService.eventObjectsFromImage({ file, prompt, metadata });
      setEvents(data.map((e: any) => ({ ...e, id: uuidv4(), status: "pending" })));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setEvents([]);
    setFile(null);
    setPrompt(null);
    setMetadata(null);
  };

  const handleSave = async () => {
    try {
      if (events.length === 0) {
        return;
      }
      setLoading(true);
      for (let e of events) {
        try {
          const { id, location, ...rest } = e;
          await eventsService.create({
            ...rest,
            location: location?.id,
          });
          // Add a success status to the event
          setEvents((prevEvents) =>
            prevEvents.map((pe) => {
              if (pe.id === e.id) {
                return { ...e, status: "success" };
              }
              return pe;
            })
          );
          continue;
        } catch (error) {
          setError(error);
        }
        // Add a failure status to the event
        setEvents((prevEvents) =>
          prevEvents.map((pe) => {
            if (pe.id === e.id) {
              return { ...e, status: "failure" };
            }
            return pe;
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEditing = (newEvent: any) => {
    if (newEvent) {
      setEvents((prevEvents) =>
        prevEvents.map((pe) => {
          if (pe.id === newEvent.id) {
            return newEvent;
          }
          return pe;
        })
      );
      setEditing(null);
    }
  };

  const handleApplyAll = () => {
    if (field && value) {
      setEvents((prevEvents) => prevEvents.map((e) => ({ ...e, [field]: value })));
      setField("");
      setValue(null);
    }
  };

  const analyzeDisabled = !file || !prompt;

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert icon={<Warning />} color="error">
              {error?.message || "Something went wrong. Check the console."}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Add Events from Image</Typography>
        </Grid>
        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <FormLabel htmlFor="description">Image</FormLabel>
          <div
            {...getRootProps()}
            style={{
              width: "100%",
              height: "200px",
              border: "1px solid #aaa",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: "5px",
              backgroundColor: "#eee",
              ...(file
                ? { backgroundImage: `url(${URL.createObjectURL(file)}`, backgroundSize: "cover" }
                : {}),
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <FormControl fullWidth>
            <FormLabel htmlFor="prompt">Prompt</FormLabel>
            <TextField id="prompt" variant="outlined" onChange={(e) => setPrompt(e.target.value)} />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel htmlFor="metadata">Metadata</FormLabel>
            <TextField
              id="metadata"
              variant="outlined"
              onChange={(e) => setMetadata(e.target.value)}
            />
          </FormControl>
          <Button
            disabled={analyzeDisabled}
            variant="contained"
            color="primary"
            onClick={handleAnalyze}
          >
            Analyze
          </Button>
        </Grid>
        {events.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Apply To All</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel>Field</FormLabel>
                    <Select
                      value={field}
                      onChange={(e) => {
                        setValue(null);
                        setField(e.target.value);
                      }}
                    >
                      <MenuItem value="">Select Field</MenuItem>
                      <MenuItem value="thumbnail">Thumbnail</MenuItem>
                      <MenuItem value="title">Title</MenuItem>
                      <MenuItem value="description">Description</MenuItem>
                      <MenuItem value="start">Start</MenuItem>
                      <MenuItem value="location">Location</MenuItem>
                      <MenuItem value="price">Price</MenuItem>
                      <MenuItem value="link">Link</MenuItem>
                      <MenuItem value="video">Video</MenuItem>
                      <MenuItem value="tags">Tags</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {field === "thumbnail" && (
                    <Thumbnail
                      imageUrl={events?.[0].thumbnail}
                      onChange={(url) =>
                        setEvents((prevEvents) => prevEvents.map((e) => ({ ...e, thumbnail: url })))
                      }
                    />
                  )}
                  {field === "title" && (
                    <FormControl fullWidth>
                      <FormLabel>Title</FormLabel>
                      <TextField
                        id="title"
                        label="Title"
                        variant="outlined"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </FormControl>
                  )}
                  {field === "description" && (
                    <FormControl fullWidth>
                      <FormLabel>Description</FormLabel>
                      <TextField
                        id="description"
                        label="Description"
                        variant="outlined"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </FormControl>
                  )}
                  {field === "start" && (
                    <FormControl fullWidth>
                      <FormLabel>Start</FormLabel>
                      <TextField
                        id="start"
                        label="Start"
                        variant="outlined"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </FormControl>
                  )}
                  {field === "location" && (
                    <FormControl fullWidth>
                      <FormLabel>Locations</FormLabel>
                      <LocationSelect onChange={(location) => setValue(location)} />
                    </FormControl>
                  )}
                  {field === "price" && (
                    <FormControl fullWidth>
                      <FormLabel>Price</FormLabel>
                      <TextField
                        id="price"
                        label="Price"
                        variant="outlined"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </FormControl>
                  )}
                  {field === "link" && (
                    <FormControl fullWidth>
                      <FormLabel>Link</FormLabel>
                      <TextField
                        id="link"
                        label="Link"
                        variant="outlined"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </FormControl>
                  )}
                  {field === "video" && (
                    <FormControl fullWidth>
                      <FormLabel>Video</FormLabel>
                      <VideoSelect onChange={(location) => setValue(location)} />
                    </FormControl>
                  )}
                  {field === "tags" && (
                    <FormControl fullWidth>
                      <FormLabel>Tags</FormLabel>
                      <TagMultiSelect
                        selectedTags={value}
                        setSelectedTags={(selectedTags: any) => setValue(selectedTags)}
                      />
                    </FormControl>
                  )}
                  {value && (
                    <Button variant="contained" color="primary" onClick={handleApplyAll}>
                      Apply
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Events</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={events}
                  getRowId={(row) => row.id}
                  columns={columns}
                  sx={{ overflowX: "scroll" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                <Icon>add</Icon>
                Save
              </Button>
            </Grid>
          </>
        )}
        <EventEditDialog
          event={editing}
          open={Boolean(editing)}
          onClose={() => setEditing(null)}
          onSave={handleSaveEditing}
        />
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default EventsFromImage;
