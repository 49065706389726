import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
} from "@mui/material";
import LoadingOverlay from "components/LoadingOverlay";

interface IDeleteItemsModalProps {
  handleDelete: () => Promise<void>;
  selectedRowIds: string[];
  loading?: boolean;
  text?: string;
  doNotDisable?: boolean;
}

const DeleteItemsModal: React.FC<IDeleteItemsModalProps> = (props) => {
  const { handleDelete, loading, selectedRowIds = [], text, doNotDisable } = props;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleSubmit = async () => {
    await handleDelete()
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        setShowDeleteDialog(false);
      });
  };
  return (
    <>
      <Button
        variant="contained"
        color="error"
        disabled={selectedRowIds.length === 0 && !doNotDisable}
        onClick={() => setShowDeleteDialog(true)}
      >
        {text ? text : `Delete ${selectedRowIds.length} Selected`}
      </Button>
      {showDeleteDialog && (
        <Dialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Warning!
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDeleteDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon>close</Icon>
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2} direction={"row"} width={"100%"}>
              <Grid
                item
                xs={12}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                flexDirection={"column"}
                display={"flex"}
                height={"100%"}
                color={"000"}
              >
                <strong>
                  Are you sure you want to delete
                  {selectedRowIds?.length > 1
                    ? ` these ${selectedRowIds.length} items? `
                    : ` this item? `}
                  This action cannot be undone.
                </strong>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container item justifyContent={"flex-end"} spacing={4}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowDeleteDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="error" onClick={handleSubmit}>
                  Delete
                </Button>
              </Grid>
            </Grid>
            <LoadingOverlay loading={loading || false} />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteItemsModal;
