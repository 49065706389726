import React from 'react';
import {
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { useGlobalContext } from 'context/GlobalContext';
import { ITagGroup } from '../../types/tagGroups';
import { ITag } from '../../types/tags';

interface Tag {
  id: number;
  title: string;
}

interface TagMultiSelectProps {
  selectedTags?: any;
  setSelectedTags?: (tags: Tag[]) => void;
}

const TagMultiSelect: React.FC<TagMultiSelectProps> = (props) => {
  const { selectedTags, setSelectedTags } = props;
  
  const {tags, tagGroups} = useGlobalContext();

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });


  const renderTagsOptionLabel = (option: any) => {
    const tag = tags.find((tag: any) => tag._id === option);
    if (tag) {
      if (tag && tagGroups.length) {
        const firstTagGroup = tagGroups.find((tagGroup: ITagGroup) =>
          tagGroup.tags.some((t: any) => t.id === tag._id)
        );
        if (firstTagGroup) {
          return `${tag.title} - ${firstTagGroup.title}`;
        }
        return tag?.title;
      }
    }
    return option || "";
  };

  return (
    <Autocomplete
      multiple
      value={selectedTags?.map((tag: any) => tag._id) || []}
      onChange={(_e, value) => {
        console.log('value', value);
        // @ts-ignore
        setSelectedTags(tags.filter((tag: any) => value.includes(tag._id)));
      }}
      filterOptions={filterOptions}
      options={tags.map((tag: ITag) => tag._id)}
      getOptionLabel={renderTagsOptionLabel}
      renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
    />
  );
};

export default TagMultiSelect;