import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { useAuth } from "context/Auth";
import rolesService from "services/roles";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { ChevronLeft } from "@mui/icons-material";
import { IRole } from "types/roles";

const Role: React.FC = () => {
  const [role, setRole] = useState<IRole>({ title: "", description: "" });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  const fetchRole = useCallback(async () => {
    try {
      setLoading(true);
      const _role: IRole = await rolesService.fetch(id);
      setRole(_role);
    } catch (e) {
      console.error("Error fetching role group:", e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id && id !== "create") {
      fetchRole();
    }
  }, [fetchRole, id]);

  // Function to delete a role
  const handleDelete = async () => {
    try {
      await rolesService.remove(id);
      navigate("/roles");
    } catch (error) {
      console.error("Error deleting role:", error);
    }
    fetchRole();
  };

  // Function to save a role
  const handleSave = async () => {
    try {
      setLoading(true);
      const newRole: IRole = {
        ...role,
      };

      if (!newRole?.title || !newRole?.description) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        newRole.createdBy = user;
        newRole.createdDate = new Date().toISOString();
        await rolesService.create(newRole);
      } else {
        newRole.updatedBy = user;
        newRole.updatedAt = new Date().toISOString();
        await rolesService.update(newRole.id, newRole);
      }
      //on successful save, navigate back to roles
      navigate("/roles");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h4">Role</Typography>
            </Grid>
            <Grid item xs={12}>
              {!id ||
                (id === "create" ? (
                  <Typography variant="body1">Create a User Role.</Typography>
                ) : (
                  <Typography variant="body1">Edit this role.</Typography>
                ))}
            </Grid>

            <Grid container item direction={"column"} xs={8} spacing={2}>
              <Grid item>
                <FormControl fullWidth>
                  <FormLabel required htmlFor="role-title">
                    Name
                  </FormLabel>
                  <TextField
                    id="role-title"
                    value={role?.title || ""}
                    required
                    onChange={(e) => setRole({ ...role, title: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <FormLabel required htmlFor="description">
                    Description
                  </FormLabel>
                  <TextField
                    id="description"
                    value={role?.description || ""}
                    required
                    onChange={(e) => setRole({ ...role, description: e.target.value })}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
        </Grid>
        <Grid item xs={12} />
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Typography variant="h4" color="alert">
              Danger Zone
            </Typography>
            <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
        <LoadingOverlay loading={loading} />
        <Snackbar
          open={snackbar.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
      </Grid>
    </BasicLayout>
  );
};

export default Role;
