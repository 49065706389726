import { OpenAIService } from '../types/OpenAI';
import { API_HOST } from '../config';
import axios from 'axios';
import TokenHandler from './base';

async function completion(prompt: string) {
  const token = TokenHandler.getToken();

  const url = new URL(`${API_HOST}/openai/completion`);

  const response = await axios.post(
    url.toString(),
    { prompt },
    {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

async function transcribeAudio(audio: File) {
  const token = TokenHandler.getToken();

  const url = new URL(`${API_HOST}/openai/transcribe-audio`);

  const formData = new FormData();
  formData.append('file', audio);

  const response = await axios.post(
    url.toString(),
    formData,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
  );
  return response.data;
}


const openAIService: OpenAIService = {
  completion,
  transcribeAudio
};

export default openAIService;