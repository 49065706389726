import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import organizationsService from "../../services/organizations";
import { IOrganization } from "types/organizations.d";

interface OrganizationSelectProps {
  value?: IOrganization;
  onChange: (organization: IOrganization) => void;
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ onChange, value }) => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const organizations = await organizationsService.fetchAll({ pageSize: 1000 });
      setOrganizations(organizations.data);
    };
    fetchOrganizations();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedOrganization = organizations.find(
      (organization: IOrganization) => organization.id === value
    );
    if (selectedOrganization) {
      onChange(selectedOrganization);
    } else {
      onChange(null);
    }
  };

  return (
    <Autocomplete
      value={value?.id || value?._id || ""}
      onChange={(e, value) => handleSelectChange(value)}
      options={organizations?.map((organization: any) => organization.id)}
      getOptionLabel={(option) =>
        organizations.find((organization: any) => organization.id === option)?.name || option
      }
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default OrganizationSelect;
