import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import NewsArticle from "./NewsArticle";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import newsService from "services/news";
import { IGenerateNewsResponse, INews } from "types/news";
import AuthorSelect from "components/AuthorSelect";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { StatusEnum } from "types/events.d";

const slugify = (text: string) => {
  return text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/\s+/g, "-");
};

const News: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [showGenerateArticle, setShowGenerateArticle] = useState(false);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const DEFAULT_CREATE_NEWS: INews = {
    _id: "",
    slug: "",
    title: "",
    paragraphs: [],
    subtitle: "",
    tagIds: [],
    tags: [],
    image: "",
    prompt: "",
    createdDate: "",
    author: undefined,
    __v: 0,
    id: "",
    content: "",
    article: "",
    updatedDate: "",
    updatedBy: "",
    summary: "",
    featured: false,
    trending: false,
    status: StatusEnum.Published,
    video: undefined,
  };

  const [news, setNews] = useState<Partial<INews & { seedImageURL?: string }>>({
    ...DEFAULT_CREATE_NEWS,
  });

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        const _news: INews = await newsService.fetch(id);
        //not all articles have status, so we set it to published by default
        if (!_news.status) {
          _news.status = StatusEnum.Published;
        }
        setNews(_news);
      } catch (e) {
        console.error("Error fetching news:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchNews();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!news.title || !news?.image) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // Check to make sure it doesn't already exist
        //TODO should this be the user object?
        news.createdBy = user.name;
        news.createdDate = new Date().toISOString();
        news.slug = slugify(news.title);
        const newsWithoutSeedImage = { ...news };
        delete newsWithoutSeedImage.seedImageURL;
        delete newsWithoutSeedImage.id;
        delete newsWithoutSeedImage._id;
        await newsService.create(newsWithoutSeedImage);
      } else {
        news.updatedDate = new Date().toISOString();
        news.updatedBy = user?.displayName;
        news.slug = slugify(news.title);
        const newsWithoutSeedImage = { ...news };
        delete newsWithoutSeedImage.seedImageURL;
        await newsService.update(news?.id, newsWithoutSeedImage);
      }
      // Redirect to listings
      navigate("/news");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await newsService.remove(id);
      navigate("/news");
    } catch (error) {
      console.error("Error deleting ad:", error);
    }
  };

  const handleGenerateClick = async () => {
    try {
      setLoading(true);
      if (!news.prompt) {
        alert("Please fill in the prompt");
        throw new Error("Please fill in all fields");
      }
      // Use openai to generate the news article
      const response: IGenerateNewsResponse = await newsService.generate({
        prompt: news.prompt,
        author: news?.author,
        image: news.seedImageURL || news?.image,
      });
      setNews({
        ...news,
        ...response,
      });
    } catch (error: any) {
      console.error("Error generating news:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">News</Typography>
        </Grid>
        {(!news?.article || showGenerateArticle) && (
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel htmlFor="name">Prompt</FormLabel>
                <TextField
                  id="prompt"
                  multiline
                  rows={9}
                  value={news?.prompt}
                  onChange={(e) => setNews({ ...news, prompt: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel htmlFor="image">Author</FormLabel>
                <AuthorSelect
                  value={news?.author}
                  onChange={(author) => setNews({ ...news, author })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                size="large"
                disabled={loading || !news?.author || !news.prompt}
                variant="contained"
                color="primary"
                onClick={handleGenerateClick}
              >
                Generate Article and Image
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        )}
        {news?.article && !showGenerateArticle && (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              size="large"
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={() => setShowGenerateArticle(true)}
            >
              Regenerate Article
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <NewsArticle news={news} setNews={setNews} />
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>

        {news?.id && (
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default News;
