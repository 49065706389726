import { IUser as User } from './users.d';
import { ITag } from "./tags";
import { ILocationData } from "./locations.d";
import { IVideoData } from "./videos.d";
import { IOrganization } from "./organizations.d";
import { IPromotion } from "./promotions.d";

export interface IEventResponse {
  page: number;
  limit: number;
  search: any;
  total: number;
  sortField: string;
  sortDirection: string;
  data: IEvent[];
}

export interface IGenerateEventImageResponse {
  image: string;
}
export enum StatusEnum {
  Published = "published",
  Pending = "pending",
}

export type StatusTypes = StatusEnum.Published | StatusEnum.Pending;

export interface IEvent {
  _id?: string;
  start: string;
  title: string;
  __v: number;
  createdDate: string;
  description?: string;
  link: string;
  location: ILocationData;
  price?: string;
  slug: string;
  status: StatusTypes;
  tagIds: string[];
  thumbnail: string;
  updatedDate: string;
  tags: ITag[];
  id: string;
  createdBy?: User | string;
  boosted?: boolean;
  locationName?: string;
  source?: string;
  updatedAt?: string;
  updatedBy?: User;
  featured?: boolean;
  video?: IVideoData;
  organization?: IOrganization;
  promotion?: IPromotion;
}

export interface Photo {
  width: number;
  height: number;
  html_attributions: string[];
  url: string;
  photo_reference: string;
  _id: string;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
