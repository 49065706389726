import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "context/Auth";

const ProtectedRoute = (props: any) => {
  const { children } = props;

  // Get the url params
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading ...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
