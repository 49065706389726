import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";

import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import itinerariesService from "services/itineraries";

const Itineraries: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },
    { field: "description", headerName: "Description", minWidth: 200, flex: 2 },
  ];

  const filterOptions: IFilterOptions = {
    title: true,
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Itineraries</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/itineraries/create">
            <Icon>add</Icon>
            Create Itinerarie
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="itineraries"
              defaultSortField="name"
              defaultSortDirection="desc"
              columns={columns}
              service={itinerariesService}
              filterOptions={filterOptions}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Itineraries;
