import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FormControl, FormLabel, Grid, IconButton, Typography, TextField, } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import { ChevronLeft } from '@mui/icons-material';
import { useAuth } from "context/Auth";
import apiKeysService from 'services/apiKeys';
import { AxiosResponse } from 'axios';
import { IApiKeyData } from 'types/apiKeys.d';
import BasicLayout from 'components/Layouts/BasicLayout';


const ApiKey: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [apiKey, setApiKey] = useState<IApiKeyData>(undefined);

  const { id } = useParams<{ id: string; }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  //fetch specific apiKey if URL has apiKey's id
  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        setLoading(true);
        const fetchedApiKey = await apiKeysService.fetch(id);
        setApiKey(fetchedApiKey);
      } catch (e) {
        console.error('Error fetching event:', e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== 'create') {
      fetchApiKey();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!apiKey.name || !apiKey.accessToken) {
        throw new Error('Please fill in all fields');
      }
      if (id === 'create') {
        apiKey.createdBy = user;
        apiKey.createdAt = new Date().toISOString();
        await apiKeysService.create(apiKey);
      }
      else {
        apiKey.updatedAt = new Date().toISOString();
        apiKey.updatedBy = user;
        await apiKeysService.update(id, apiKey);
      }
      //Redirect to listings if no errors
      navigate('/apiKeys');
    } catch (error: any) {
      console.error('Error saving apiKey:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateToken = useCallback(async () => {
    try {
      setLoading(true);
      const { accessToken } = await apiKeysService.generateToken();
      setApiKey({ ...apiKey, accessToken });
    } catch (error) {
      console.error('Error generating token:', error);
    } finally {
      setLoading(false);
    }
  }, [id, apiKey]);

  const handleDelete = async () => {
    try {
      await apiKeysService.remove(id);
      navigate('/apiKeys');
    } catch (error) {
      console.error('Error deleting apiKey:', error);
    }
  };
  console.log('apiKey', apiKey)
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          <IconButton onClick={() => navigate('/apiKeys')}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">ApiKey</Typography>
        </Grid>

        <Grid item xs={12}>
          {error && <Typography color="error">{error}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel htmlFor='name'>Name</FormLabel>
            <TextField
              id='name'
              value={apiKey?.name || ''}
              onChange={(e) => setApiKey({ ...apiKey, name: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel htmlFor='name'>Access Token</FormLabel>
            <TextField
              id='accessToken'
              name="accessToken"
              value={apiKey?.accessToken || ''}
              disabled
            />
          </FormControl>
          <Button variant="contained" color="secondary" size="small" onClick={handleGenerateToken}>Generate Token</Button>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Grid>
        {id !== 'create' && <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" color="alert">Danger Zone</Typography>
              <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>Delete</Button>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </BasicLayout>
  );
};

export default ApiKey;
