import React, { useCallback, useState } from "react";
import { FormControl, FormLabel, Grid, Typography, TextField } from "@mui/material";
import { useDropzone } from "react-dropzone";
import openAIService from "services/openai";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
const TranscribeAudio: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");
  const [text, setText] = useState("");

  const onDrop = useCallback(async (acceptedFiles: any) => {
    try {
      console.log("acceptedFiles:", acceptedFiles);
      if (!acceptedFiles?.length) {
        setError("Audio must be an mp3 or mp4");
        return;
      }
      setError(null);
      setLoading(true);
      const audioFile = acceptedFiles[0];
      setFileName(audioFile.name);
      const response = await openAIService.transcribeAudio(audioFile);
      if (response.text) {
        setText(response.text);
      } else {
        throw new Error("No text returned");
      }
    } catch (e) {
      console.error("Error transcribing audio:", e);
    } finally {
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: {
      "video/mp4": [],
      "audio/mp3": [],
      "audio/mp4": [],
      "audio/mpeg": [],
    },
  });

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormLabel htmlFor="description">Video or Audio File</FormLabel>
          {error && <Typography color="error">{error}</Typography>}
          <div
            {...getRootProps()}
            style={{
              width: "100%",
              height: "400px",
              border: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel htmlFor="fileName">File Name</FormLabel>
            <TextField id="fileName" value={fileName} disabled />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel htmlFor="transcription">Transcription</FormLabel>
            <TextField id="transcription" multiline rows={8} value={text} />
          </FormControl>
        </Grid>
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default TranscribeAudio;
