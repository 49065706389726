import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  createFilterOptions,
  Autocomplete,
} from "@mui/material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import { upload } from "services/images";
import { useDropzone } from "react-dropzone";
import BasicLayout from "components/Layouts/BasicLayout";
import { ChevronLeft } from "@mui/icons-material";
import teamsService from "services/teams";
import { ITeam } from "types/teams";
import { IUser } from "types/users";
import usersService from "services/users";

const Team: React.FC = () => {
  const [team, setTeam] = useState<ITeam | undefined>(undefined);
  const [users, setUsers] = useState<IUser[]>([]);

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        setLoading(true);
        const _team: ITeam = await teamsService.fetch(id);
        setTeam(_team);
      } catch (e) {
        console.error("Error fetching team:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchTeam();
    }
  }, [id]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const _users: { data: IUser[] } = await usersService.fetchAll();
        setUsers(_users.data);
      } catch (e) {
        console.error("Error fetching team:", e);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [id]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;
      for (let file of acceptedFiles) {
        const response = await upload(file, {
          path: "/teams/upload",
        });
        setTeam({
          ...team,
          imageUrl: response.imageUrl,
        });
      }
    },
    [team]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const renderUserOptionLabel = (option: any) => {
    const user = users?.find((user: any) => user.id === option);
    if (user) {
      return user?.email;
    }
    return option || "";
  };

  // Function to delete a team
  const handleDelete = async () => {
    try {
      await teamsService.remove(id);
      navigate("/teams");
    } catch (error) {
      console.error("Error deleting team:", error);
    }
  };

  // Function to save a team
  const handleSave = async () => {
    try {
      setLoading(true);
      const newTeam: ITeam = {
        ...team,
      };

      if (!newTeam?.name) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        newTeam.createdBy = user;
        newTeam.createdDate = new Date().toISOString();
        await teamsService.create(newTeam);
      } else {
        newTeam.updatedBy = user;
        newTeam.updatedAt = new Date().toISOString();
        await teamsService.update(newTeam.id, newTeam);
      }

      //on successful save, navigate back to teams
      navigate("/teams");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h4">Team</Typography>
            </Grid>
            <Grid item xs={12}>
              {!id ||
                (id === "create" ? (
                  <Typography variant="body1">Create a new team.</Typography>
                ) : (
                  <Typography variant="body1">Edit this team.</Typography>
                ))}
            </Grid>
            <Grid container item direction={"row"} xs={12} spacing={2}>
              {team?.imageUrl ? (
                <Grid
                  item
                  container
                  direction={"column"}
                  xs={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid
                    item
                    container
                    style={{
                      border: "1px solid #ccc",
                      height: "200px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid display={"flex"} item>
                      <img
                        src={team?.imageUrl}
                        style={{ width: "100%", height: "180px" }}
                        alt={"team"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        cursor: "pointer",
                        fontWeight: 500,
                      }}
                      variant={"subtitle2"}
                      color={"red"}
                      onClick={() => setTeam({ ...team, imageUrl: "" })}
                    >
                      (remove image)
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <FormLabel htmlFor="description">Image *</FormLabel>
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "15px",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                  </div>
                </Grid>
              )}
              <Grid container item direction={"column"} xs={8} spacing={2}>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel required htmlFor="team-name">
                      Name
                    </FormLabel>
                    <TextField
                      id="team-name"
                      value={team?.name || ""}
                      required
                      onChange={(e) => setTeam({ ...team, name: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <TextField
                      id="description"
                      value={team?.description || ""}
                      required
                      onChange={(e) => setTeam({ ...team, description: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="members">Members</FormLabel>
                    <Autocomplete
                      multiple
                      value={team?.members || []}
                      onChange={(_e, value) => {
                        setTeam({
                          ...team,
                          members: value as IUser[],
                        });
                      }}
                      filterOptions={filterOptions}
                      options={users ? users?.map((user: any) => user.id) : []}
                      getOptionLabel={renderUserOptionLabel}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
        </Grid>
        <Grid item xs={12} />
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Typography variant="h4" color="alert">
              Danger Zone
            </Typography>
            <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
        <LoadingOverlay loading={loading} />
        <Snackbar
          open={snackbar.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
      </Grid>
    </BasicLayout>
  );
};

export default Team;
