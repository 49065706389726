import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// import useTagGroups from "hooks/useTagGroups";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import tagsService from "services/tags";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";

const Tags: React.FC = () => {
  // const { tagGroups } = useTagGroups();

  const columns: GridColDef[] = [
    {
      field: "thumbnail",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value} alt="Tag URL" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
    //TODO API currently not returning tag group. Maybe use tag group call to get this
    // {
    //   field: "tagGroup",
    //   headerName: "Tag Group",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params: any) => {
    //     return (
    //       <Box>
    //         {tagGroups?.find((tg: any) => tg.id === params.row.tagGroup)?.name ||
    //           "Not assigned to group"}
    //       </Box>
    //     );
    //   },
    // },
  ];

  const filterOptions: IFilterOptions = {
    title: true,
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Tags</Typography>
        </Grid>
        <Grid item xs={8} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/tags/create">
            <Icon>add</Icon>
            Create New Tag
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PaginatedDataGrid
            collectionName="tags"
            defaultSortField="title"
            defaultSortDirection="desc"
            columns={columns}
            service={tagsService}
            filterOptions={filterOptions}
          />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Tags;
