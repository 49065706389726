import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import adsService from "services/ads";
import { upload } from "services/images";
import { IAd } from "types/ads.d";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";

const Ad: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [ad, setAd] = useState<IAd | undefined>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const _ad = await adsService.fetch(id);
        setAd(_ad);
      } catch (e) {
        console.error("Error fetching ad:", e);
      }
    };
    if (id && id !== "create") {
      fetchAd();
    }
  }, [id]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;

      for (let file of acceptedFiles) {
        const response = await upload(file, {
          path: "/images/upload",
        });
        setAd({
          ...ad,
          image: response.imageUrl,
        });
      }
    },
    [ad]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!ad.title || !ad.link) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        ad.createdBy = user;
        ad.createdAt = new Date().toISOString();
        await adsService.create(ad);
      } else {
        ad.updatedDate = new Date().toISOString();
        ad.updatedBy = user;
        await adsService.update(ad.id, ad);
      }
      // Redirect to listings on success
      navigate("/ads");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await adsService.remove(id);
      navigate("/ads");
    } catch (error) {
      console.error("Error deleting ad:", error);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={10}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Ad</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <TextField
                      id="name"
                      value={ad?.title || ""}
                      onChange={(e) => setAd({ ...ad, title: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="alt">Description</FormLabel>
                    <TextField
                      id="alt"
                      value={ad?.description || ""}
                      onChange={(e) => setAd({ ...ad, description: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="url">Ad Link</FormLabel>
                    <TextField
                      id="link"
                      value={ad?.link || ""}
                      onChange={(e) => setAd({ ...ad, link: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={ad?.image}
                    style={{ width: "100%" }}
                    alt={`Ad for ${ad?.description || ad?.title}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel htmlFor="description">Ad</FormLabel>
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "200px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Ad;
