import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Box,
  Icon,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";
import itinerarysService from "services/itineraries";
import { IItinerary } from "types/itinerary.d";
import { Edit, Warning } from "@mui/icons-material";
import eventsService from "services/events";
import { IEvent } from "types/events.d";
import AITextField from "components/AITextField";

const Itinerary: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [itinerary, setItinerary] = useState<IItinerary>(undefined);
  const [events, setEvents] = useState<IEvent[]>([]);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { loading: contextLoading, refetchOrganizations } = useGlobalContext();

  const columns: GridColDef[] = [
    {
      field: "thumbnail",
      headerName: "",
      width: 100,
      renderCell: (params: any) => (
        <img src={params?.value} alt="Event" style={{ width: "50px" }} />
      ),
    },
    { field: "title", headerName: "Title", width: 400 },
    {
      field: "start",
      headerName: "Start",
      width: 200,
      renderCell: (params: any) => {
        const d = new Date(params?.value);
        return <span>{d.toLocaleString()}</span>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 300,
      renderCell: (params: any) => <span>{params?.value?.name}</span>,
    },
    {
      field: "city",
      headerName: "City",
      width: 300,
      renderCell: (params: any) => <span>{params?.row?.location?.city}</span>,
    },

  ];

  useEffect(() => {
    const fetchItinerary = async () => {
      try {
        const itinerary = await itinerarysService.fetch(id);
        setItinerary(itinerary);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };

    if (id && id !== "create") {
      fetchItinerary();
    }

  }, [id]);

  useEffect(() => {
    const fetchAllEvents = async () => {
      try {
        if (!itinerary?.start) {
          return;
        }

        const [year, month, day] = itinerary.start.split('-');
        const correctedDate = new Date(Number(year), Number(month) - 1, Number(day));

        const start = new Date(correctedDate);
        start.setHours(0, 0, 0, 0);

        const end = new Date(correctedDate);
        end.setHours(23, 59, 59, 999);

        const response = await eventsService.fetchAll({
          pageSize: 100,
          page: 0,
          includeDrafts: false,
          query: [
            {
              field: "status",
              operator: "eq",
              value: "published",
            },
            {
              field: "start",
              operator: "gte",
              value: start.toISOString(),
            },
            {
              field: "start",
              operator: "lte",
              value: end.toISOString(),
            },
          ]
        })
        setEvents(response.data);
      } catch (e) {
        console.error("Error fetching events:", e);
      }
    };
    fetchAllEvents();
  }, [itinerary?.start]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newItinerary: IItinerary = {
        ...itinerary,
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
      };

      if (!newItinerary.title) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        newItinerary.createdBy = user;
        newItinerary.createdDate = new Date().toISOString();
        await itinerarysService.create(newItinerary);
      } else {
        newItinerary.updatedBy = user;
        newItinerary.updatedDate = new Date().toISOString();
        await itinerarysService.update(newItinerary.id, newItinerary);
      }
      refetchOrganizations();
      navigate("/itinerarys");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await itinerarysService.remove(id);
      refetchOrganizations();
      navigate("/itineraries");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  console.log('itinerary', itinerary);
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Itinerary</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container direction={"column"} style={{ padding: "20px" }} spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <AITextField
                      id="title"
                      label="Title"
                      required={true}
                      value={itinerary?.title || ""}
                      onChange={(value: string) => setItinerary((prev) => ({ ...prev, title: value }))}
                      prompt="From the following details, create a description of an event. The title should be descriptive and engaging and be at least 3 sentences long."
                      promptMode="modal"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <AITextField
                      id="description"
                      label="Description"
                      value={itinerary?.description || ""}
                      onChange={(value: string) =>
                        setItinerary((prev) => ({ ...prev, description: value }))
                      }
                      prompt="From the following details, create a description of an event. The title should be descriptive and engaging and be at least 3 sentences long."
                      promptMode="modal"
                      multiline
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="date">Date</FormLabel>
                    <TextField
                      id="date"
                      type="date"
                      value={itinerary?.start || ""}
                      onChange={(e) => {
                        console.log('e.target.value', e.target.value);
                        setItinerary((prev) => ({ ...prev, start: e.target.value }));
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Events</Typography>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={events || []}
                columns={columns}
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  minWidth: "400px",
                }}
                autoHeight
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(data: any) => { // Array of selected row ids
                  setItinerary((prev) => ({
                    ...prev,
                    events: data
                  }));
                }}
                rowSelectionModel={itinerary?.events as string[] || []}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => navigate("/events/create")}>
                Add Event
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={contextLoading || loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Itinerary;
