import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
// import useTagGroups from "hooks/useTagGroups";
import PaginatedDataGrid from "components/PaginatedDataGrid";

import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import teamsService from "services/teams";

const Teams: React.FC = () => {
  // const { tagGroups } = useTagGroups();
  const renderMemberCount = (
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    console.log(params);
    return <span>{params.value.length}</span>;
  };

  const columns: GridColDef[] = [
    {
      field: "imageUrl",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value} alt="Tag URL" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
    { field: "description", headerName: "Description", flex: 2, minWidth: 200 },
    {
      field: "members",
      headerName: "Member Count",
      renderCell: renderMemberCount,
      flex: 1,
      minWidth: 200,
    },
  ];

  const filterOptions: IFilterOptions = {
    name: true,
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Teams</Typography>
        </Grid>
        <Grid item xs={8} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/teams/create">
            <Icon>add</Icon>
            Create New Team
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PaginatedDataGrid
            collectionName="teams"
            defaultSortField="name"
            defaultSortDirection="desc"
            columns={columns}
            service={teamsService}
            filterOptions={filterOptions}
          />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Teams;
